import { Component, forwardRef, OnInit } from '@angular/core';
import {
  Calendar,
  CalendarApi,
  CalendarOptions,
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  EventDropArg,
  EventInput,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, {
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import { TranslateService } from '@ngx-translate/core';

import {
  AbstractEntityTypeService,
  AbstractUserManagementService,
  AuthGuard,
  BaseActionKey,
  EntityType,
  ExecutedAction,
  ExecutedActionBehaviour,
  NotificationsService,
  ObjectsUtilityService,
  PageContextService,
  PrgError,
  TabConfig,
  User,
  ViewMode,
} from '@prg/prg-core-lib';
import { UserProfileService } from '../../../users/services/user-profile-service/user-profile.service';
import {
  CalendarLegend,
  UpdatedUserAvailabilitiesResponse,
  UserAvailability,
} from '../../models/user-availability.model';
import { UserAvailabilitiesService } from '../../services/user-availabilities.service';
import { UserAvailabilityRecurringComponent } from '../user-availability-recurring/user-availability-recurring.component';

@Component({
  selector: 'app-user-availabilities-calendar',
  templateUrl: './user-availabilities-calendar.page.html',
  styleUrls: ['./user-availabilities-calendar.page.scss'],
})
export class UserAvailabilitiesCalendarPage implements OnInit {
  private readonly AVAILABILITIES_USER_ROLE = 'Technician';
  private readonly AVAILABILITIES_ENTITY_TYPE = 'UserAvailability';
  private isOnCallTranslation = this.translateService.instant(
    'entities.useravailability.fields.isoncall'
  );
  private isNotOnCallTranslation = this.translateService.instant(
    'entities.useravailability.fields.isnotoncall'
  );
  public users: User[] = [];
  private availabilities: UserAvailability[] = [];
  private calendarViewStartDate: Date;
  private calendarViewEndDate: Date;
  private calendarApi: CalendarApi;
  private hasUsersLoaded: boolean = false;
  public displayAvailabilitiesModal: boolean = false;
  public displayAvailabilityModal: boolean = false;
  public displayAddAvailabilitiesButton: boolean = false;
  public displayRemoveAvailabilitiesButton: boolean = false;
  public selectedUsers: User[] = [];
  public selectedDates: Date[] = [];
  public userAvailabilitiesEntityType: EntityType;
  public selectedUserAvailability: UserAvailability;
  public userAvailabilityViewMode: ViewMode = ViewMode.Read;
  public actionBehaviour: ExecutedActionBehaviour = new ExecutedActionBehaviour(
    { redirectToList: false, reloadData: false, changeViewModeToRead: false }
  );

  public calendarLegends: CalendarLegend[];
  public calendarLegendsSelected: boolean[] = [true, true];

  public calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'title',
      center: 'prev,today,next',
      right: 'dayGridMonth,dayGridWeek,dayGridDay',
    },
    weekends: true,
    editable: true,
    eventDurationEditable: true,
    eventResizableFromStart: true,
    selectable: false,
    unselectAuto: false,
    dayMaxEvents: true,
    height: '100%',
    locale: this.translateService.instant('app.locale'),
    buttonText: this.translateService.instant('components.calendar.buttons'),
    events: [],
    select: this.onCalendarDatesSelected.bind(this),
    unselect: this.onCalendarDatesUnSelected.bind(this),
    eventDrop: this.onCalendarEventDrop.bind(this),
    eventResize: this.onCalendarEventResizeStopped.bind(this),
    eventClick: this.onCalendarEventClicked.bind(this),
    datesSet: this.onCalendarDatesSetted.bind(this),
  };

  public componentsTabs: TabConfig[] = null;
  public userCanCreateAvailabilities: boolean = true;

  constructor(
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private userManagementService: AbstractUserManagementService,
    private userAvailabilitiesService: UserAvailabilitiesService,
    private entityTypeService: AbstractEntityTypeService,
    private objectsUtilityService: ObjectsUtilityService,
    private pageContextService: PageContextService,
    private userProfileService: UserProfileService,
    private authGuard: AuthGuard
  ) {}

  public async ngOnInit(): Promise<void> {
    // need for load calendar bundle first (from https://github.com/fullcalendar/fullcalendar-angular/blob/main/app/src/app.component.ts)
    forwardRef(() => Calendar);

    this.users = await this.userProfileService.getUsersByRoleAsync(
      this.AVAILABILITIES_USER_ROLE
    );
    this.userAvailabilitiesEntityType =
      await this.entityTypeService.getAllEntityTypeDataByName(
        this.AVAILABILITIES_ENTITY_TYPE
      );

    this.setUserPermissions();

    this.pageContextService.subscribeVariable(
      'ListDragDropComponent',
      'selectedElements',
      async (value) => {
        this.selectedUsers = value ?? [];
        if (this.calendarOptions != null) {
          this.calendarOptions.selectable = this.selectedUsers.length > 0;
        }
        this.hasUsersLoaded = true;
        if (
          !this.calendarLegendsSelected[0] &&
          !this.calendarLegendsSelected[1]
        ) {
          return;
        }
        await this.getAvailabilitiesAndUpdateCalendarEventsAsync();
      }
    );
  }

  private displayNewAvailabilityModal() {
    this.userAvailabilityViewMode = ViewMode.Edit;
    const userIdGuiSettingsProperty =
      this.userAvailabilitiesEntityType.properties.find(
        (p) => p.name === 'userId'
      );
    const userIdGuiSettings = JSON.parse(userIdGuiSettingsProperty.guiSettings);

    userIdGuiSettings['controlName'] = 'multiSelect';
    userIdGuiSettings['options'] = this.objectsUtilityService.clone(this.users);
    userIdGuiSettings['enableExpression'] = 'true';

    userIdGuiSettingsProperty.guiSettings = JSON.stringify(userIdGuiSettings);

    this.userAvailabilitiesEntityType = this.objectsUtilityService.clone(
      this.userAvailabilitiesEntityType
    );

    this.componentsTabs = [
      new TabConfig({
        icon: 'pi pi-calendar',
        key: 'recurring',
        translationPath: 'pages.user-availabilities.tabs',
        componentData: {
          component: UserAvailabilityRecurringComponent,
          inputs: {
            availability: this.selectedUserAvailability,
          },
        },
      }),
    ];

    this.displayAvailabilityModal = true;
  }

  private setCalendarEventsFromAvailabilities(): void {
    const updatedEvents = [];
    this.availabilities.forEach((availability) => {
      const user = this.users.find((u) => u.id === availability.userId);
      const newEndDate = new Date(availability.endDate);
      newEndDate.setDate(newEndDate.getDate() + 1);
      const newEvent: EventInput = {
        id: availability.id,
        allDay: true,
        title: user != null ? user.name : '',
        start: new Date(availability.startDate),
        end: newEndDate,
        backgroundColor: availability.isOnCall
          ? this.isOnCallTranslation.backgroundColor
          : this.isNotOnCallTranslation.backgroundColor,
      };

      updatedEvents.push(newEvent);
    });

    this.calendarLegends = [
      {
        backgroundColor: this.isOnCallTranslation.backgroundColor,
        color: null,
        icon: null,
        label: this.isOnCallTranslation.label,
        count: this.calcAvailabilityTypeTotals(true),
      },
      {
        backgroundColor: this.isNotOnCallTranslation.backgroundColor,
        color: null,
        icon: null,
        label: this.isNotOnCallTranslation.label,
        count: this.calcAvailabilityTypeTotals(false),
      },
    ];

    this.calendarOptions.events = updatedEvents;
  }

  private calcAvailabilityTypeTotals(isOnCall: boolean): number {
    return this.availabilities
      .filter((a) => a.isOnCall == isOnCall)
      .reduce((accumulator, a) => {
        const startDate = <Date>a.startDate;
        let endDate = <Date>a.endDate;
        if (this.calendarViewEndDate < endDate) {
          endDate = new Date(this.calendarViewEndDate);
        }
        const parsedStartDate = new Date(
          startDate.getFullYear() +
            '-' +
            ('0' + (startDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + startDate.getDate()).slice(-2)
        );
        const parsedEndDate = new Date(
          endDate.getFullYear() +
            '-' +
            ('0' + (endDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + endDate.getDate()).slice(-2)
        );

        let days =
          (parsedEndDate.getTime() - parsedStartDate.getTime()) /
          (1000 * 3600 * 24);
        days++;
        return accumulator + days;
      }, 0);
  }
  private async getAvailabilitiesAsync(): Promise<void> {
    const onCall =
      this.calendarLegendsSelected[0] == this.calendarLegendsSelected[1]
        ? null
        : this.calendarLegendsSelected[0];
    this.availabilities =
      await this.userAvailabilitiesService.getUserAvailabilitiesByDateIntervalAsync(
        this.calendarViewStartDate,
        this.calendarViewEndDate,
        this.selectedUsers.map((u) => u.id),
        onCall
      );
  }

  private async addAvailabilitiesAsync(
    startDate: Date,
    endDate: Date,
    onCall: boolean,
    userIds: string[]
  ): Promise<void> {
    let result: UpdatedUserAvailabilitiesResponse;
    try {
      result = await this.userAvailabilitiesService.addUserAvailabilitiesAsync(
        startDate,
        endDate,
        onCall,
        false,
        userIds,
        this.selectedUserAvailability
      );

      this.updateListOfAvailabilities(
        result.addedAvailabilities,
        result.removedAvailabilities
      );
    } catch (ex: any) {
      this.catchException(ex);

      const confirmation =
        await this.notificationsService.prgConfirmationService(
          'messages.overlapped-availabilities',
          null,
          false
        );

      if (confirmation) {
        result =
          await this.userAvailabilitiesService.addUserAvailabilitiesAsync(
            startDate,
            endDate,
            onCall,
            true,
            userIds,
            this.selectedUserAvailability
          );
        this.updateListOfAvailabilities(
          result.addedAvailabilities,
          result.removedAvailabilities
        );
      } else {
        this.setCalendarEventsFromAvailabilities();
      }
    }

    this.displayAvailabilitiesModal = false;
  }

  private async updateUserAvailabilityAsync(
    id: string,
    startDate: Date,
    endDate: Date,
    onCall: boolean
  ): Promise<void> {
    let result: UpdatedUserAvailabilitiesResponse;
    try {
      result = await this.userAvailabilitiesService.updateUserAvailabilityAsync(
        id,
        startDate,
        endDate,
        onCall,
        false
      );
      this.updateListOfAvailabilities(
        result.addedAvailabilities,
        result.removedAvailabilities
      );
    } catch (ex: any) {
      this.catchException(ex);

      const confirmation =
        await this.notificationsService.prgConfirmationService(
          'messages.work-orders-in-availabilities',
          null,
          false
        );

      if (confirmation) {
        result =
          await this.userAvailabilitiesService.updateUserAvailabilityAsync(
            id,
            startDate,
            endDate,
            onCall,
            true
          );
        this.updateListOfAvailabilities(
          result.addedAvailabilities,
          result.removedAvailabilities
        );
      } else {
        this.setCalendarEventsFromAvailabilities();
      }
    }

    this.displayAvailabilityModal = false;
    this.displayAvailabilitiesModal = false;
  }

  private async getAvailabilitiesAndUpdateCalendarEventsAsync(): Promise<void> {
    await this.getAvailabilitiesAsync();
    this.setCalendarEventsFromAvailabilities();
  }

  private async updateAvailabilityFromCalendarEventAsync(
    arg: EventDropArg | EventResizeDoneArg
  ): Promise<void> {
    const availability = this.availabilities.find((a) => a.id === arg.event.id);
    if (availability != null) {
      const d = new Date(arg.event.end);

      d.setDate(d.getDate() - 1); // subtract 1 days

      await this.updateUserAvailabilityAsync(
        arg.event.id,
        arg.event.start,
        d,
        availability.isOnCall
      );
    }
  }

  public toggleAvailabilityState(index: number): void {
    this.calendarLegendsSelected[index] = !this.calendarLegendsSelected[index];
    if (!this.calendarLegendsSelected[0] && !this.calendarLegendsSelected[1]) {
      this.availabilities = [];
      this.setCalendarEventsFromAvailabilities();
      return;
    }
    this.getAvailabilitiesAndUpdateCalendarEventsAsync();
  }

  /**
   * this function is called when a user is dropped in calendar date
   * @param event
   * @returns
   */
  public onUserDropped(event: any): void {
    const el = event.srcElement;
    if (el == null) {
      return;
    }

    const date = el.parentElement.dataset.date;
    if (date == null) {
      return;
    }

    this.selectedUserAvailability = new UserAvailability();
    this.selectedUserAvailability.isOnCall = false;
    this.selectedUserAvailability.isRecurring = false;
    this.selectedUserAvailability.startDate = date;
    this.selectedUserAvailability.endDate = date;
    this.selectedUserAvailability.userId = [this.selectedUsers[0].id];
    this.displayNewAvailabilityModal();
  }

  public async onExecutedAction(action: ExecutedAction): Promise<void> {
    if (
      action.baseAction.key === BaseActionKey.Edit ||
      action.baseAction.key === BaseActionKey.Cancel
    ) {
      return;
    }

    if (action.baseAction.key === 'create') {
      if (Array.isArray(this.selectedUserAvailability.userId)) {
        this.selectedUserAvailability.userId =
          this.selectedUserAvailability.userId[0];
      }

      await this.addAvailabilitiesAsync(
        action.actionResult.entity.startDate,
        action.actionResult.entity.endDate,
        action.actionResult.entity.isOnCall,
        action.actionResult.entity.userId
      );
    } else if (action.baseAction.key === 'update') {
      await this.updateUserAvailabilityAsync(
        action.actionResult.entity.id,
        action.actionResult.entity.startDate,
        action.actionResult.entity.endDate,
        action.actionResult.entity.isOnCall
      );
    } else if (action.baseAction.key === BaseActionKey.Delete) {
      await this.getAvailabilitiesAndUpdateCalendarEventsAsync();
    }

    this.displayAvailabilityModal = false;
  }

  public onAvailabilityModalClosed(): void {
    this.calendarApi.unselect();
  }

  public onAvailabilitiesModalClosed(): void {
    this.calendarApi.unselect();
  }

  public async onCalendarDatesSetted(arg: DatesSetArg): Promise<void> {
    this.calendarApi = arg.view.calendar;
    this.calendarViewStartDate = arg.start;
    // remove single day from end date, as selection ends at 00:00:00 of the following day
    arg.end.setDate(arg.end.getDate() - 1);
    this.calendarViewEndDate = arg.end;
    if (this.hasUsersLoaded) {
      if (
        !this.calendarLegendsSelected[0] &&
        !this.calendarLegendsSelected[1]
      ) {
        return;
      }
      await this.getAvailabilitiesAndUpdateCalendarEventsAsync();
    }
  }

  public onCalendarDatesUnSelected(): void {
    this.selectedDates = [];
  }

  public onCalendarDatesSelected(arg: DateSelectArg): void {
    // remove single day from end date, as selection ends at 00:00:00 of the following day
    arg.end.setDate(arg.end.getDate() - 1);
    const auxStartDate = new Date(arg.start);
    const auxEndDate = new Date(arg.end);
    this.selectedDates = [auxStartDate, auxEndDate];

    this.displayRemoveAvailabilitiesButton =
      this.availabilities.find(
        (a) =>
          (a.startDate <= auxStartDate && a.endDate >= auxStartDate) ||
          (a.startDate <= auxEndDate && a.endDate >= auxEndDate) ||
          (a.startDate >= auxStartDate && a.endDate <= auxEndDate)
      ) != null;

    if (this.selectedDates.length) {
      this.selectedUserAvailability = new UserAvailability();
      this.selectedUserAvailability.startDate = this.selectedDates[0];
      this.selectedUserAvailability.endDate =
        this.selectedDates[this.selectedDates.length - 1];
      this.selectedUserAvailability.isOnCall = false;
      this.selectedUserAvailability.isRecurring = false;
      this.selectedUserAvailability.userId = this.selectedUsers.map(
        (u) => u.id
      );
    }
    this.displayAvailabilitiesModal = this.selectedDates.length > 0;
  }

  public async onCalendarEventDrop(arg: EventDropArg): Promise<void> {
    await this.updateAvailabilityFromCalendarEventAsync(arg);
  }
  public async onCalendarEventResizeStopped(
    arg: EventResizeDoneArg
  ): Promise<void> {
    await this.updateAvailabilityFromCalendarEventAsync(arg);
  }

  public async onCalendarEventClicked(arg: EventClickArg) {
    this.userAvailabilityViewMode = ViewMode.Read;
    this.componentsTabs = null;
    this.selectedUserAvailability =
      await this.entityTypeService.getEntityTypeElementById(
        this.AVAILABILITIES_ENTITY_TYPE,
        arg.event.id
      );

    const userIdGuiSettingsProperty =
      this.userAvailabilitiesEntityType.properties.find(
        (p) => p.name === 'userId'
      );
    const userIdGuiSettings = JSON.parse(userIdGuiSettingsProperty.guiSettings);

    userIdGuiSettings['controlName'] = 'dropdown';
    userIdGuiSettings['enableExpression'] = 'false';
    userIdGuiSettings['options'] = this.users.filter(
      (u) => u.id === this.selectedUserAvailability.userId
    );

    userIdGuiSettingsProperty.guiSettings = JSON.stringify(userIdGuiSettings);

    this.userAvailabilitiesEntityType = this.objectsUtilityService.clone(
      this.userAvailabilitiesEntityType
    );

    this.displayAvailabilityModal = true;
  }

  public onAddButtonClicked() {
    this.selectedUserAvailability = new UserAvailability();
    this.selectedUserAvailability.isOnCall = false;
    this.selectedUserAvailability.isRecurring = false;
    this.selectedUserAvailability.userId = this.selectedUsers.map((u) => u.id);
    this.displayNewAvailabilityModal();
  }

  public async onAddAvailabilitiesClicked(onCall: boolean): Promise<void> {
    this.selectedUserAvailability.userId = null;
    await this.addAvailabilitiesAsync(
      this.selectedDates[0],
      this.selectedDates[this.selectedDates.length - 1],
      onCall,
      this.selectedUsers.map((u) => u.id)
    );
  }

  public async onRemoveAvailabilitiesClicked(): Promise<void> {
    let updatedUserAvailabilitiesResponse: UpdatedUserAvailabilitiesResponse;
    try {
      updatedUserAvailabilitiesResponse =
        await this.userAvailabilitiesService.removeUserAvailabilitiesAsync(
          this.selectedDates[0],
          this.selectedDates[this.selectedDates.length - 1],
          false,
          this.selectedUsers.map((u) => u.id)
        );
      this.updateListOfAvailabilities(
        updatedUserAvailabilitiesResponse.addedAvailabilities,
        updatedUserAvailabilitiesResponse.removedAvailabilities
      );
    } catch (ex: any) {
      this.catchException(ex);

      const confirmation =
        await this.notificationsService.prgConfirmationService(
          'messages.work-orders-in-availabilities',
          null,
          false
        );

      if (confirmation) {
        updatedUserAvailabilitiesResponse =
          await this.userAvailabilitiesService.removeUserAvailabilitiesAsync(
            this.selectedDates[0],
            this.selectedDates[this.selectedDates.length - 1],
            true,
            this.selectedUsers.map((u) => u.id)
          );
        this.updateListOfAvailabilities(
          updatedUserAvailabilitiesResponse.addedAvailabilities,
          updatedUserAvailabilitiesResponse.removedAvailabilities
        );
      } else {
        this.setCalendarEventsFromAvailabilities();
      }
    }

    this.displayAvailabilitiesModal = false;
  }

  /**
   * this function add or/and remove avalabilities from calendar
   * @param newAvailabilities
   * @param removedAvailabilities
   */
  private updateListOfAvailabilities(
    newAvailabilities: UserAvailability[],
    removedAvailabilities: string[]
  ): void {
    if (newAvailabilities != null && newAvailabilities.length) {
      newAvailabilities.forEach((element) => {
        if (
          this.calendarLegendsSelected[0] != this.calendarLegendsSelected[1] &&
          element.isOnCall == this.calendarLegendsSelected[0]
        ) {
          this.availabilities.push(element);
        } else if (
          this.calendarLegendsSelected[0] &&
          this.calendarLegendsSelected[1]
        ) {
          this.availabilities.push(element);
        }
      });
    }

    if (removedAvailabilities != null && removedAvailabilities.length) {
      this.availabilities = this.availabilities.filter(
        (a) => removedAvailabilities.find((r) => r == a.id) == null
      );
    }

    this.setCalendarEventsFromAvailabilities();
  }

  private catchException(ex: any): void {
    if (ex.status != 409) {
      if (!ex?.error) {
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: `Status Code: ${ex?.status}. `,
            detailKey: ex?.statusText,
          })
        );
      } else if (ex?.error && ex?.status != 0) {
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: `Status Code:  ${ex?.status}. `,
            detailKey: ex?.error?.ErrorMessage,
          })
        );
      } else {
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: `Status Code:  ${ex?.status}.`,
            detailKey: ex?.statusText,
          })
        );
      }

      return;
    }
  }

  public setUserPermissions() {
    if (!this.authGuard.isGranted('update', 'useravailability')) {
      this.calendarOptions.eventDurationEditable = false;
      this.calendarOptions.editable = false;
    }

    if (!this.authGuard.isGranted('create', 'useravailability')) {
      this.userCanCreateAvailabilities = false;
    }
  }
}
