import { Component, OnInit } from '@angular/core';
import { MapComponentTypesToClass } from '@prg/prg-core-lib';
import { TicketsListComponent } from '../ticket-list/tickets-list.component';

@Component({
  selector: 'dashboard-aux',
  templateUrl: './dashboard-aux.component.html',
  styleUrls: ['./dashboard-aux.component.scss'],
})
export class DashboardAuxComponent implements OnInit {
  public mapComponentsType: Map<string, any> = MapComponentTypesToClass;

  ngOnInit() {
    this.mapComponentsType.set('ticket-list-card', TicketsListComponent);
  }
}
