import { Component, OnInit } from '@angular/core';
import {
  ArrayUtilityService,
  BaseAction,
  BaseActionKey,
  BaseField,
  DynamicFormActionOutput,
  DynamicFormFieldChangeOutput,
  ObjectsUtilityService,
  TreeSelectResourceConfiguration,
  UtilityService,
  ViewMode,
} from '@prg/prg-core-lib';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  WorkOrder,
  WorkOrderChecklist,
  WorkOrderChecklistOperation,
} from '../../../../../work-orders/models/work-order-model';

import { WorkOrderChecklistEntitiesOperationNames } from '../../model/work-order-checklist-entities-operation-names-enum';
import { WorkOrderChecklistService } from '../../services/work-order-checklist.service';
import { OPERATIONS_DYNAMIC_FORM } from './work-order-operations.configs';

@Component({
  selector: 'app-work-order-checklist-operations',
  templateUrl: './work-order-checklist-operations.component.html',
  styleUrls: ['./work-order-checklist-operations.component.scss'],
})
export class WorkOrderChecklistOperationsComponent implements OnInit {
  /** The form fields to build dynamic form */
  public operationsDynFormFields: BaseField[] = this.arrayUtilityService.clone(
    OPERATIONS_DYNAMIC_FORM.fields
  );
  /** The form actions to build dynamic form */
  public operationsDynFormActions: BaseAction[] =
    this.arrayUtilityService.clone(OPERATIONS_DYNAMIC_FORM.actions);

  public workOrderChecklist: WorkOrderChecklist;
  public workOrder: WorkOrder;

  public workOrderChecklistOperation: WorkOrderChecklistOperation =
    new WorkOrderChecklistOperation({});

  private workOrderChecklistOperationCopy: WorkOrderChecklistOperation;

  private resourceTypeSelected: string = null;

  /**
   * The view mode type of the form. Default is "read"
   * @type {ViewMode}
   */
  public viewMode: ViewMode = ViewMode.Edit;

  /** Constructor, just gets the required services from DI */
  constructor(
    private arrayUtilityService: ArrayUtilityService,
    private utilityService: UtilityService,
    private objectsUtilityService: ObjectsUtilityService,
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private workOrderChecklistService: WorkOrderChecklistService
  ) {}

  ngOnInit(): void {
    this.workOrderChecklist = this.objectsUtilityService.clone(
      this.dynamicDialogConfig?.data['workOrderChecklist']
    );
    this.workOrder = this.objectsUtilityService.clone(
      this.dynamicDialogConfig?.data['workOrder']
    );
    this.workOrderChecklistOperation.workOrderChecklistId =
      this.workOrderChecklist.id;
    const aux = this.getNextOrderOfOperation(this.workOrderChecklist);
    if (aux != null) {
      if (aux.order != null) {
        this.workOrderChecklistOperation.order = aux.order + 1;
      } else {
        this.workOrderChecklistOperation.order = 0;
      }
    } else {
      this.workOrderChecklistOperation.order = 0;
    }
    this.workOrderChecklistOperationCopy = this.objectsUtilityService.clone(
      this.workOrderChecklistOperation
    );
    this.updateResourcesTreeSelectParentId();
  }

  /**update resources tree parent id */
  private updateResourcesTreeSelectParentId(): void {
    const treeSelectResouceField = <TreeSelectResourceConfiguration>(
      this.operationsDynFormFields.find((field) => field.key === 'resourceId')
    );

    treeSelectResouceField.resourceParentId = this.workOrder.resourceId;
  }

  async onActionExecuted(event: DynamicFormActionOutput): Promise<void> {
    switch (event.action) {
      case BaseActionKey.Create:
        this.viewMode = ViewMode.Edit;
        break;
      case BaseActionKey.Save:
        /*     if (this.resourceTypeSelected != null) {
          event.formEntity['resourceTypeId'] = this.resourceTypeSelected;
        }*/
        try {
          const responseOperationUpdate =
            await this.workOrderChecklistService.manageWorkOrderChecklistDataByEntityServiceAsync(
              'workorderchecklistoperation',
              WorkOrderChecklistEntitiesOperationNames.Create,
              event.formEntity
            );

          if (responseOperationUpdate?.entity != null) {
            this.workOrderChecklistOperation = responseOperationUpdate?.entity;
            this.workOrderChecklistOperationCopy =
              this.objectsUtilityService.clone(
                this.workOrderChecklistOperation
              );

            const numberOfTasksUpdate =
              responseOperationUpdate?.data?.NumberOfTasks;

            if (this.workOrderChecklistOperation.id != null) {
              this.dynamicDialogRef.close({
                workOrderChecklistOperation: this.workOrderChecklistOperation,
                numberOfTasks:
                  numberOfTasksUpdate != null
                    ? numberOfTasksUpdate
                    : this.workOrderChecklist.numberOfTasks,
              });
            }
          }
        } catch (e) {
          console.log(e);
        }
        this.viewMode = ViewMode.Read;
        break;
      case BaseActionKey.Cancel:
        this.workOrderChecklistOperation = this.objectsUtilityService.clone(
          this.workOrderChecklistOperationCopy
        );
        break;

      default:
        break;
    }
  }

  fieldChangeOutput(event: DynamicFormFieldChangeOutput): void {
    if (event?.event?.node?.data != null) {
      this.resourceTypeSelected = event.event.node.data.ResourceTypeId;
    } else {
      this.resourceTypeSelected = null;
    }
  }

  public getNextOrderOfOperation(
    workOrderChecklist: WorkOrderChecklist
  ): WorkOrderChecklist | null {
    if (
      this.arrayUtilityService.isNotNullOrEmptyArray(
        workOrderChecklist.workOrderChecklistOperations
      )
    ) {
      return workOrderChecklist.workOrderChecklistOperations.reduce(
        (prev, current) => {
          return prev?.order > current?.order ? prev : current;
        }
      );
    } else {
      return null;
    }
  }
}
