import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  ArrayUtilityService,
  AuthGuard,
  BaseField,
  EvalUtilityService,
  FormGroupService,
  IsLoadingDataService,
  ObjectsUtilityService,
  UtilityService,
} from '@prg/prg-core-lib';
import { SelectButton } from 'primeng/selectbutton';
import { Subscription } from 'rxjs';
import {
  OperationValueStates,
  WorkOrderChecklistOperation,
  WorkOrderChecklistParameter,
  WorkOrderStates,
} from '../../../../../work-orders/models/work-order-model';

@Component({
  selector: 'app-checklist-parameters',
  templateUrl: './work-order-checklist-parameters.component.html',
  styleUrls: ['./work-order-checklist-parameters.component.scss'],
})
export class WorkOrderChecklistParametersComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private checklistOperationComplete: WorkOrderChecklistOperation;

  public isLoadingApp: boolean = false;
  /**
   * Input checklist Operation from parent
   * @param {WorkOrderChecklistOperation} workOrderChecklistOperation
   */
  @Input() set checklistOperation(
    workOrderChecklistOperation: WorkOrderChecklistOperation
  ) {
    this._checklistOperation = this.objectsUtilityService.clone(
      workOrderChecklistOperation
    );
    this.checklistOperationComplete = this.objectsUtilityService.clone(
      workOrderChecklistOperation
    );
    if (
      this.arrayUtilityService.isNotNullOrEmptyArray(
        this._checklistOperation.workOrderChecklistParameters
      ) &&
      this._checklistOperation.workOrderChecklistParameters.length > 20
    ) {
      this._checklistOperation.workOrderChecklistParameters =
        this.checklistOperation.workOrderChecklistParameters.slice(0, 20);
      this.lastElementId = this.objectsUtilityService
        .clone(this._checklistOperation.workOrderChecklistParameters)
        ?.pop()?.id;

      this.lengthParameters =
        this.checklistOperationComplete.workOrderChecklistParameters.length;
    }
  }

  /**
   * A getter for checklist Operation
   * @returns {WorkOrderChecklistOperation}
   */
  public get checklistOperation(): WorkOrderChecklistOperation {
    return this._checklistOperation;
  }
  public lastElementId: string;
  public lengthParameters: number;

  public numberOfElementsToInsertDefault: number = 15;
  /**
   * Input work order state from parent
   * @type {WorkOrderStates | string}
   */
  @Input() workOrderState: WorkOrderStates | string;

  /**
   * Input form fields parent
   * @type {BaseField[]}
   */
  @Input() fields: BaseField[];

  /**
   * A event emitter for parent of parameters value
   * @type {EventEmitter<WorkOrderChecklistOperation>}
   */
  @Output() outputValueParameters =
    new EventEmitter<WorkOrderChecklistOperation>();

  /**
   * aux checklist operation
   * @type {WorkOrderChecklistOperation}
   * @private
   */
  private _checklistOperation: WorkOrderChecklistOperation;

  /**
   * Property with type Form Group which bind to the parameters form
   */
  @Input() formParameters: FormGroup;

  @Input() set mainScroll(mainScroll) {
    if (
      mainScroll != null &&
      this.lastElementId != null &&
      !this.isLoading &&
      document?.getElementById(this.lastElementId) != null
    ) {
      if (
        document.getElementById(this.lastElementId).offsetTop +
          125 +
          16 -
          (mainScroll.clientHeight + mainScroll.scrollTop) <
        this.scrollThreshold
      ) {
        this.addMoreParametersToForm();
      }
    }
  }

  private scrollThreshold = 100;

  /**
   * Loading form
   * @type {boolean}
   */
  public isLoading: boolean = true;

  private subscription: Subscription;

  /**
   * An array of values that were already used on form
   * @type {any[]}
   */
  public historyValues: any[] = [];

  public currentButtonClick: any = {
    index: null,
    id: null,
  };

  /**
   * Constructor
   * @param {FormGroupService} formGroupService
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {UtilityService} utilityService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {FormBuilder} formBuilder
   * @param {ChangeDetectorRef} changeDetectorRef
   * @param isLoadingDataService
   * @param authGuard
   */
  constructor(
    private formGroupService: FormGroupService,
    private objectsUtilityService: ObjectsUtilityService,
    private utilityService: UtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private isLoadingDataService: IsLoadingDataService,
    private authGuard: AuthGuard,
    private evalUtilityService: EvalUtilityService
  ) {}

  /**
   * ngAfterViewInit to detect changes on view
   */
  public ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * ngOnInit
   */
  public ngOnInit(): void {
    this.subscription = this.isLoadingDataService
      .getIsLoadingObservable()
      .subscribe((loading) => {
        this.isLoadingApp = loading.isLoading;
        if (this.isLoadingApp == false) {
          this.currentButtonClick.index = null;
          this.currentButtonClick.id = null;
        }
      });
    if (this._checklistOperation != null) {
      this.initParametersFormArray();
      this._checklistOperation.workOrderChecklistParameters.map((value) => {
        this.historyValues.push(value.value);
      });
      this.isLoading = false;
    }
  }

  /**
   * A getter for the form array controls
   * @returns {any}
   */
  public get parametersValue(): FormArray {
    return this.formParameters.controls[
      this._checklistOperation.id
    ] as FormArray;
  }

  /**
   * Method that builds form array
   * @private
   */
  private initParametersFormArray(): void {
    this.formParameters.removeControl(this._checklistOperation.id);
    this.formParameters.addControl(
      this._checklistOperation.id,
      this.formBuilder.array([])
    );
    if (
      this._checklistOperation?.workOrderChecklistParameters != null &&
      this._checklistOperation?.workOrderChecklistParameters.length > 0
    ) {
      const auxFormGroups = this.formGroupService.toFormGroupMultipleObjects(
        this._checklistOperation?.workOrderChecklistParameters,
        this.fields
      );

      auxFormGroups.forEach((auxFormGroup) => {
        if (
          this.workOrderState != WorkOrderStates.OnGoing &&
          !this.authGuard.isGranted('workorder:change-on-complete', null, null)
        ) {
          auxFormGroup.disable();
        }

        this.parametersValue.push(auxFormGroup);
      });
    }
  }
  public addMoreParametersToForm() {
    const currentLength =
      this._checklistOperation?.workOrderChecklistParameters?.length;
    if (currentLength != null && currentLength < this.lengthParameters) {
      const numberOfElementsRemaining = this.lengthParameters - currentLength;
      const numberOfElementsToInsert =
        numberOfElementsRemaining > this.numberOfElementsToInsertDefault
          ? this.numberOfElementsToInsertDefault
          : numberOfElementsRemaining;
      const auxParam = this.arrayUtilityService.clone(
        this.checklistOperationComplete.workOrderChecklistParameters.slice(
          currentLength,
          currentLength + numberOfElementsToInsert
        )
      );
      this._checklistOperation.workOrderChecklistParameters.push(...auxParam);
      const auxFormGroups = this.formGroupService.toFormGroupMultipleObjects(
        auxParam,
        this.fields
      );
      auxFormGroups.forEach((auxFormGroup) => {
        if (
          this.workOrderState != WorkOrderStates.OnGoing &&
          !this.authGuard.isGranted('workorder:change-on-complete', null, null)
        ) {
          auxFormGroup.disable();
        }

        this.parametersValue.push(auxFormGroup);
      });
      auxParam.map((value) => {
        this.historyValues.push(value.value);
      });
      if (
        this._checklistOperation.workOrderChecklistParameters.length ==
        this.lengthParameters
      ) {
        this.lastElementId = null;
      } else {
        this.lastElementId = this.objectsUtilityService
          .clone(this._checklistOperation.workOrderChecklistParameters)
          ?.pop()?.id;
      }
    }
  }

  /**
   * Method that performs an eval expression for visibility purpose
   * @param {string} expression
   * @param {boolean} defaultValue
   * @param context
   * @returns {boolean}
   */
  public evalExpression(
    expression: string,
    defaultValue: boolean = false,
    context: any
  ): boolean {
    if (!expression) return defaultValue;

    return this.evalUtilityService.evalFunction(expression, context);
  }

  /**
   * Method that handles actions after a parameter has been selected
   * @param event
   * @param {WorkOrderChecklistParameter} param
   * @param {number} i
   * @param {SelectButton} selectedButton
   */
  public onSelectParam(
    event: any,
    param: WorkOrderChecklistParameter,
    i: number,
    selectedButton: SelectButton
  ): void {
    this.currentButtonClick.index = i;
    this.currentButtonClick.id = this.checklistOperation.id;
    if (this.historyValues[i] == event.option) {
      selectedButton.writeValue(null);
      this.historyValues[i] = null;
      this._checklistOperation.workOrderChecklistParameters[i].value = null;
    } else {
      this.historyValues[i] = event.option;
    }
    this.checklistOperationComplete.workOrderChecklistParameters[i].value =
      this._checklistOperation.workOrderChecklistParameters[i].value;
    this.setNullValueToNextParametersValue(i);
    this.checkResultValidity(i);
    this.outputValueParameters.next(this.checklistOperationComplete);
  }

  /**
   * Method that set the next value of parameter to null (conditional parameters)
   * @param {number} i
   * @private
   */
  private setNullValueToNextParametersValue(i: number) {
    this._checklistOperation.workOrderChecklistParameters.forEach(
      (param, index) => {
        if (index > i && param.visibilityExpression != null) {
          this._checklistOperation.workOrderChecklistParameters[index].value =
            null;
          this.checklistOperationComplete.workOrderChecklistParameters[
            index
          ].value = null;
          this.historyValues[index] = null;
          this.parametersValue.get([index]).value.value = null;
        }
      }
    );
  }

  /**
   * Method that handles actions after user make an input on paremeter
   * @param event
   * @param i
   */
  public onInputParameters(event: any, i): void {
    this.currentButtonClick.index = i;
    this.currentButtonClick.id = this.checklistOperation.id;
    this._checklistOperation.workOrderChecklistParameters[i].value =
      event?.toString();
    this.checklistOperationComplete.workOrderChecklistParameters[i].value =
      event?.toString();
    this.setOperationValueStateOnMultipleParameters();
    this.checklistOperationComplete.operationValueStateId =
      this._checklistOperation.operationValueStateId;
    this.outputValueParameters.next(this.checklistOperationComplete);
  }

  /**
   * Method that verify if a parameter is closed to show an icon instantly on view
   * @param {number} i
   * @returns {any}
   */
  private checkResultValidity(i: number): any {
    let checkParameterClose: boolean = false;

    if (
      this._checklistOperation.workOrderChecklistParameters[i].value != null &&
      this.arrayUtilityService.isNotNullOrEmptyArray(
        this._checklistOperation.workOrderChecklistParameters[i].closeValues
      )
    ) {
      checkParameterClose =
        !!this._checklistOperation.workOrderChecklistParameters[
          i
        ].closeValues.find(
          (value) =>
            value ==
            this._checklistOperation.workOrderChecklistParameters[i].value
        );
    }
    if (checkParameterClose) {
      this._checklistOperation.operationValueStateId =
        OperationValueStates.Closed;
    } else {
      this._checklistOperation.operationValueStateId =
        OperationValueStates.Open;
    }
    this.checklistOperationComplete.operationValueStateId =
      this._checklistOperation.operationValueStateId;
  }

  /**
   * Method that sets operation value state on multiple parameters
   */
  private setOperationValueStateOnMultipleParameters() {
    const checkNullValues =
      this.checklistOperationComplete.workOrderChecklistParameters.filter(
        (param) => param.value == null
      );

    if (this.arrayUtilityService.isNullOrEmptyArray(checkNullValues)) {
      this._checklistOperation.operationValueStateId =
        OperationValueStates.Closed;
    } else {
      this._checklistOperation.operationValueStateId =
        OperationValueStates.Open;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }
}
