import { Component, Input, OnInit } from '@angular/core';
import { PageContextService, UtilityService } from '@prg/prg-core-lib';

@Component({
  selector: 'list-drag-drop',
  templateUrl: './list-drag-drop.component.html',
  styleUrls: ['./list-drag-drop.component.scss'],
})
export class ListDragDropComponent implements OnInit {
  constructor(
    private pageContextService: PageContextService,
    private utilityService: UtilityService
  ) {}

  @Input() dragDisabled: boolean = false;

  @Input() set elements(elements: any[]) {
    this._elements = elements;
    this._filteredElements = elements;
  }
  get elements(): any[] {
    return this._filteredElements;
  }

  private _elements: any[] = [];
  private _filteredElements: any[] = [];

  public selectedElements: any[] = [];

  public searchValue: string = '';

  ngOnInit() {
    this.pageContextService.setVariableData(
      'ListDragDropComponent',
      'selectedElements',
      []
    );
  }

  public onDragStarted(event: any, element: any): void {
    const el = event.srcElement;
    if (el == null) {
      return;
    }

    const id = el.dataset.id;
    if (id == null) {
      return;
    }

    this.toggleElement(element);
  }

  /**
   *
   * @param event this function put an backgroung on calendar cell hovered
   * @returns
   */
  public onDragged(event: any): void {
    const elements = document.elementsFromPoint(event.x, event.y);
    const el = elements.find((e) => e.classList.contains('fc-day'));

    const otherEls = document.querySelectorAll('.fc-day.fc-hovered');
    if (otherEls != null && otherEls.length) {
      for (let i = 0; i < otherEls.length; i++) {
        otherEls[i].classList.remove('fc-hovered');
      }
    }

    if (el == null) {
      return;
    }
    if (!el.classList.contains('fc-hovered')) {
      el.classList.add('fc-hovered');
    }
  }

  public elementAreSelected(element: any): boolean {
    return this.selectedElements.find((e) => e.id === element.id) != null;
  }

  public toggleElement(element: any, event: any = null): void {
    if (event != null && event.ctrlKey) {
      const indexOfElement = this.selectedElements.findIndex(
        (e) => e.id === element.id
      );

      if (indexOfElement > -1) {
        this.selectedElements.splice(indexOfElement, 1);
      } else {
        this.selectedElements.push(element);
      }
    } else if (
      this.selectedElements.length != 1 ||
      this.selectedElements.find((e) => e.id === element.id) == null
    ) {
      this.selectedElements = [];
      this.selectedElements.push(element);
    } else {
      return;
    }

    this.pageContextService.setVariableData(
      'ListDragDropComponent',
      'selectedElements',
      this.selectedElements
    );
  }

  /**
   * this function filter elements by search value
   */
  private onSearchChange(value: any): void {
    if (value == null || !value.length) {
      this._filteredElements = this._elements;
      return;
    } else {
      this._filteredElements = [];
    }

    this._elements.forEach((element) => {
      if (element.name.toLowerCase().includes(value)) {
        this._filteredElements.push(element);
      }
    });
  }

  public onSearchChangeDebounce = this.utilityService.debounce((event) => {
    this.onSearchChange(event);
  }, 1000);

  /**
   * toogle selection elements
   */
  public toogleSelection(): void {
    if (this.selectedElements.length) {
      this.selectedElements = [];
    } else {
      this.selectedElements = this._filteredElements;
    }
    this.pageContextService.setVariableData(
      'ListDragDropComponent',
      'selectedElements',
      this.selectedElements
    );
  }

  /**
   * clear search
   */
  public clearSearch(): void {
    this.searchValue = '';
    this._filteredElements = this._elements;
  }
}
