import { Validators } from '@angular/forms';
import {
  InputTextConfiguration,
  ReferenceType,
  SelectConfiguration,
} from '@prg/prg-core-lib';
import { TICKETS_ORDERS } from '../../models/ticket.model';

/**
 * Interface that define the properties to be used as settings in Nav  Icon Component
 */
export interface PrgTicketsListSettingsModel {
  itemName: string;
  defaultFilters: {
    ticketStateId: string;
    orderBy: any;
  };
}

/**
 * Constant that define the key , configuration settings and settings for Nav  Icon Component
 * @type {{staticSettings: string, configurationSettings: {fields: any[]}, key: string}}
 */
export const TICKET_LIST_SETTINGS = {
  key: 'ticket-list-card',
  configurationSettings: {
    fields: [
      new InputTextConfiguration({
        key: 'itemName',
        disabledOutputEvent: false,
        validators: [Validators.required],
        basePathTranslation:
          'components.ticktes.components.ticket-list-card.fields',
        orderField: 1,
      }),
      new SelectConfiguration({
        key: 'ticketStateId',
        showClear: true,
        optionLabel: 'name',
        optionValue: 'id',
        appendTo: 'body',
        referenceType: ReferenceType.LookupTable,
        referenceName: 'ticketstates',
        disabledOutputEvent: false,
        basePathTranslation:
          'components.ticktes.components.ticket-list-card.fields',
        orderField: 2,
        propertyBasePath: 'defaultFilters',
      }),

      new SelectConfiguration({
        key: 'orderBy',
        showClear: true,
        optionLabel: 'keyLabel',
        optionValue: '',
        appendTo: 'body',
        dataKey: 'dataKey',
        options: TICKETS_ORDERS,
        disabledOutputEvent: false,
        basePathTranslation:
          'components.ticktes.components.ticket-list-card.fields',
        orderField: 4,
        propertyBasePath: 'defaultFilters',
      }),
    ],
  },
  staticSettings: {
    itemName: 'ticketList',
    defaultFilters: {
      ticketStateId: null,
      orderBy: null,
    },
  },
};
