import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  PrgChatModule,
  PrgCoreDynamicFormModule,
  PrgSharedComponentsModule,
} from '@prg/prg-core-lib';
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';

import { EditorModule } from 'primeng/editor';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TagModule } from 'primeng/tag';
import { ToolbarModule } from 'primeng/toolbar';
import { WorkOrderChecklistCardsPage } from './components/work-order-checklist-cards/work-order-checklist-cards.page';
import { WorkOrderChecklistObsPage } from './components/work-order-checklist-obs/work-order-checklist-obs.page';
import { WorkOrderChecklistOperationsComponent } from './components/work-order-checklist-operations/work-order-checklist-operations.component';
import { WorkOrderChecklistParametersComponent } from './components/work-order-checklist-parameters/work-order-checklist-parameters.component';
import { WorkOrderChecklistPage } from './components/work-order-checklist/work-order-checklist.page';
import { WorkOrderOperationHelpTextComponent } from './components/work-order-operation-help-text/work-order-operation-help-text.component';

import { WorkOrderChecklistRoutingModule } from './work-order-checklist-routing.module';

@NgModule({
  declarations: [
    WorkOrderChecklistCardsPage,
    WorkOrderChecklistPage,
    WorkOrderChecklistParametersComponent,
    WorkOrderChecklistObsPage,
    WorkOrderOperationHelpTextComponent,
    WorkOrderChecklistOperationsComponent,
  ],
  imports: [
    CommonModule,
    WorkOrderChecklistRoutingModule,
    DataViewModule,
    ToolbarModule,
    DropdownModule,
    FormsModule,
    SelectButtonModule,
    InputNumberModule,
    InputTextareaModule,
    ButtonModule,
    RippleModule,
    ReactiveFormsModule,
    IonicModule,
    TagModule,
    TranslateModule,
    EditorModule,
    DialogModule,
    PrgCoreDynamicFormModule,
    VirtualScrollerModule,
    PrgChatModule,
    PrgSharedComponentsModule,
  ],
})
export class WorkOrderChecklistModule {}
