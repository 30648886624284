import { BaseEntity, BaseNote, GenericRelation, User } from '@prg/prg-core-lib';
import { Tag } from '../../Core/models/tag.model';
import { ResourceTypes } from '../../resources/Models/resource';
import { Recurrence } from '../../user-availabilities/models/user-availability.model';
import { WorkOrderConsumable } from './consumable-model';

/**
 * WorkOrder Model
 */
export class WorkOrder extends BaseEntity {
  /**
   *  Description of work order
   */
  description?: string;
  /**
   * State of Work Order
   */
  workOrderStateId: WorkOrderStates | string;

  /**
   * actual start date and time of the work order
   * @type {string}
   */
  startDate?: string;

  /**
   * actual end date and time of the work order
   * @type {string}
   */
  finishDate?: string;

  /**
   * Scheduled date and time in datetime for the work order to start
   * @type {string}
   */
  plannedStartDate?: Date;

  /**
   * Scheduled date and time in datetime for the work order to finish
   * @type {string}
   */
  plannedFinishDate?: Date;

  /**
   * Park id where work order will take place
   * @type {string}
   */
  resourceId?: string;

  /**
   * Array of Checklist ids
   * @type {Checklist[]}
   */
  checklistsIds?: string[];
  /**
   * Array of work order Checklists
   * @type {Checklist[]}
   */
  workOrderChecklists?: WorkOrderChecklist[];
  /**
   * Consumables used on work order
   * @type {WorkOrderConsumable[]}
   */
  workOrderConsumables?: WorkOrderConsumable[];

  /**
   * Timers of Work Order
   * @type {WorkOrderTimer[]}
   */
  workOrderTimers?: WorkOrderTimer[];
  /**
   * Work order notes
   * @type {WorkOrderNote[]}
   */
  workOrderNotes?: WorkOrderNote[];

  /**
   * Parts replacements on work order
   * @type {WorkOrderReplacement[]}
   */
  workOrderReplacements?: WorkOrderReplacement[];

  /**
   * Work order assigned users
   * @type {WorkOrderReplacement[]}
   */
  workOrderUsers?: WorkOrderUser[];

  /**
   * other operations of workorder
   */
  otherOperations?: ChecklistOperation[];

  /**
   * Name of resource
   * @type {string}
   */
  resourceName?: string;

  /**
   * Is Recurring
   * @type {boolean}
   */
  isRecurring?: boolean;

  /**
   * Recurrence
   * @type {Recurrence}
   */
  public recurrence: Recurrence;

  /**
   * RecurrenceId
   * @type {string}
   */
  public recurrenceId: string;

  /**
   * Work Order Auto Number
   * @type {string}
   */
  workOrderAutoNumber?: string;

  /**
   * Work order Signature
   * @type {string}
   */
  imageUrl?: string;

  /**
   * Responsible Signature name
   * @type {string}
   */
  responsibleSignatureName?: string;

  /**
   * Type of Work Order
   * @type {string}
   */
  workOrderTypeId?: string | WorkOrderTypes;
  /**
   * generic relation
   * @type {GenericRelation}
   */
  genericRelation?: GenericRelation;

  /**
   * Sent to client
   * @type {boolean}
   */
  sentToClient?: boolean;

  /**
   * Sent to client
   * @type {boolean}
   */
  sentToClientDate?: Date | string;

  /**
   * Department Id
   * @type {string}
   */
  departmentId?: string;

  /**
   * Tags
   * @type {Tag[]}
   */
  tags?: Tag[];

  /**
   * Constructor
   * this constructor receives the fields sent by the app,
   * and creates an object with the fields sent and the values per default
   * @param init
   */
  constructor(init?: Partial<WorkOrder>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Enum that defines the state of  work orders LookUpTable
 */
export enum WorkOrderStates {
  Finished = 'workorderstates.finished',
  Canceled = 'workorderstates.cancel',
  Scheduled = 'workorderstates.scheduled',
  OnGoing = 'workorderstates.ongoing',
  Closed = 'workorderstates.closed',
}

/**
 * Class Work Order Note
 */
export class WorkOrderNote extends BaseNote {
  /**
   * WorkOrder Id
   * @type {string}
   */
  workOrderId: string;

  /**
   * Constructor
   * @param {Partial<WorkOrderNote>} init
   */
  constructor(init?: Partial<WorkOrderNote>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Work Order Checklist Operation Note Model
 */
export class WorkOrderChecklistOperationNote extends BaseNote {
  /**
   * workOrder Checklist Operation Id
   * @type {string}
   */
  workOrderChecklistOperationId: string;

  /**
   * Constructor
   * @param {Partial<WorkOrderChecklistOperationNote>} init
   */
  constructor(init?: Partial<WorkOrderChecklistOperationNote>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Work Order User Class
 */
export class WorkOrderUser extends BaseEntity {
  /**
   * WorkOrder Id
   * @type {string}
   */
  workOrderId: string;

  /**
   * User Id
   * @type {string}
   */
  userId: string;

  /**
   * User
   * @type {string}
   */
  user: User;

  constructor(init?: Partial<WorkOrderUser>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Base Timer Model
 */
export class BaseTimer extends BaseEntity {
  /**
   * Initial Time of the interval in DateTime
   * @type {string}
   */
  initialTime: string;

  /**
   * Final time of the interval in DateTime
   * @type {string}
   */
  finalTime: string;

  /**
   * Type of timer : work or travel timer
   * @type {WorkOrderTimerTypes | string}
   */
  timerTypeId: WorkOrderTimerTypes | string;

  /**
   * Constructor
   * @param {Partial<WorkOrderTimer>} init
   */
  constructor(init?: Partial<BaseTimer>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * WorkOrderTimer Model
 */
export class WorkOrderTimer extends BaseTimer {
  /**
   * Work order Id
   * @type {string}
   */
  workOrderId: string;

  /**
   * Constructor
   * @param {Partial<WorkOrderTimer>} init
   */
  constructor(init?: Partial<WorkOrderTimer>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Enum that defines the type of timers to use on property timerType of WorkOrderTimer
 */
export enum WorkOrderTimerTypes {
  Work = 'workordertimertypes.work',
  Travel = 'workordertimertypes.travel',
}

/**
 * Interface WorkOrderTimerControl
 */
export interface WorkOrderTimerControl {
  /**
   * if it has timer
   */
  hasTimer?: boolean;
  /**
   * if it has a running timer
   */
  onGoingTimer?: boolean;
  /**
   * list of timers
   */
  timers?: WorkOrderTimer[];
  /**
   * total timer
   */
  totalTimer: TimerUnits;
}

/**
 * export Timer Units
 */
export interface TimerUnits {
  /**
   * days
   */
  days: string | number;
  /**
   * hours
   */
  hours: string | number;
  /**
   * minutes
   */
  minutes: string | number;
  /**
   * seconds
   */
  seconds: string | number;
}

export class Client extends BaseEntity {
  /**
   * Client Fiscal Code
   * @type {string}
   */
  public fiscalCode?: string;

  constructor(init?: Partial<Client>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Class that defines the spare parts used on Resource
 */
export class WorkOrderReplacement extends BaseEntity {
  /**
   * Description
   * @type {string}
   */
  public description: string;

  /**
   * The reason for the replacement
   * @type {string | ReplacementReasons}
   */
  public replacementReasonId?: string | ReplacementReasons;

  /**
   * old resource id
   * @type {string | null}
   */
  public oldResourceId?: string | null;

  /**
   * new resource id
   * @type {string | null}
   */
  public newResourceId?: string | null;

  /**
   * position of the resource
   * @type {string | null}
   */
  public position?: string | null;

  /**
   * notes of the replacement
   * @type {string | null}
   */
  public notes?: string | null;

  /**
   * Workspace id
   */
  public workspaceId: string;

  /**
   *  Work Order id
   */
  public workOrderId: string | null;

  /**
   * Resources data
   * @type {any}
   */
  public resourcesData: string | any;

  /**
   * Constructor
   * @param {Partial<WorkOrderReplacement>} init
   */
  constructor(init?: Partial<WorkOrderReplacement>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Enum that defines the reason for asset replacement in work order LookUpTable
 */
export enum ReplacementReasons {
  Wear = 'replacementreasons.wear',
  Damage = 'replacementreasons.damage',
  Other = 'replacementreasons.other',
}

export class BaseChecklist extends BaseEntity {
  /**
   * Checklist Description
   * @type {string}
   */
  public description?: string;
  /**
   * total number of tasks
   * @type {number}
   */
  public numberOfTasks?: number;
  /**
   * total number of completed tasks
   * @type {number}
   */
  public completedTasks?: number;

  /**
   * order of checklist to show on view
   * @type {number}
   */
  public order?: number;

  /**
   * Constructor
   * @param {Partial<BaseChecklist>} init
   */
  constructor(init?: Partial<BaseChecklist>) {
    super();
    Object.assign(this, init);
  }
}

export class Checklist extends BaseChecklist {
  /**
   * A list of checklist operations
   * @type {ChecklistOperation[]}
   */
  public checklistOperations?: ChecklistOperation[];

  /**
   * Constructor
   * @param {Partial<Checklist>} init
   */
  constructor(init?: Partial<Checklist>) {
    super();
    Object.assign(this, init);
  }
}
export class BaseChecklistOperation extends BaseEntity {
  /**
   * Operation description
   * @type {string}
   */
  public description?: string;

  /**
   * Type of resource used in this operation
   * @type {string | ResourceTypes | null}
   */
  public resourceTypeId?: string | ResourceTypes | null; // Lookup Table

  /**
   * order of Operation to show on view
   * @type {number}
   */
  public order?: number;

  /**
   * Help text of operation
   * @type {string}
   */
  public helpText?: string;

  /**
   * Template to use to create parameters
   * @type {string}
   */
  public operationParametersTypesId?: string | OperationParametersTypes;

  /**
   * Operation Group Id
   * @type {string}
   */
  public operationGroupId?: string;

  /**
   * Operation Group
   * @type {OperationGroup}
   */
  public operationGroup?: OperationGroup;

  /**
   * Constructor
   * @param {Partial<BaseChecklistOperation>} init
   */
  constructor(init?: Partial<BaseChecklistOperation>) {
    super();
    Object.assign(this, init);
  }
}
export class ChecklistOperation extends BaseChecklistOperation {
  /**
   * A list of checklist parameters
   * @type {ChecklistParameter[] | null}
   */
  public checklistParameters?: ChecklistParameter[] | null;
  /**
   * Checklist Id
   * @type {string}
   */
  public checklistId?: string;
  /**
   * Checklist operations children
   * @type {ChecklistOperation[]}
   */
  public checklistOperations?: ChecklistOperation[];
  /**
   * Checklist operation parent
   * @type {string | null}
   */
  public checklistOperationId?: string | null;

  /**
   * Operation parameters Resource type id
   * @type {string | null}
   */
  public OperationParametersResourceTypeId?: string | ResourceTypes;

  /**
   * Constructor
   * @param {Partial<ChecklistOperation>} init
   */
  constructor(init?: Partial<ChecklistOperation>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Enum that defines the operation parameters Type
 */
export enum OperationParametersTypes {
  Decimal = 'operationparametertypes.decimal',
  CNCNA = 'operationparametertypes.cncna',
  OKRI = 'operationparametertypes.okri',
}

export class BaseChecklistParameter extends BaseEntity {
  /**
   * Type of resource used in this parameter
   * @type {string | ResourceTypes | null}
   */
  public resourceTypeId?: string | ResourceTypes | null; // Lookup Table: parque , inversor etc

  /**
   * Data type of parameter
   * @type {string | ParameterDataTypes | null}
   */
  public parameterDataTypeId?: string | ParameterDataTypes | null; // Lookup Table :  int , decimal,

  /**
   * Field type of parameter
   * @type {string | ParameterFieldTypes | null}
   */
  public parameterFieldTypeId?: string | ParameterFieldTypes | null; // Lookup Table :  select , inputNumber

  /**
   * An array of possible values of parameter
   * @type {any[]}
   */
  public options?: any | any[];

  /**
   * Close Values to set parameter as closed
   * @type {any[]}
   */
  public closeValues?: any | any[];

  /**
   * Gui settings of parameter (future)
   * @type {string}
   */
  public guiSettings?: string;

  /**
   * Visibility expression of parameter
   * @type {string}
   */
  public visibilityExpression?: string;

  /**
   * oOder of parameter to show on view
   * @type {number}
   */
  public order?: number;

  /**
   * Constructor
   * @param {Partial<BaseChecklistParameter>} init
   */
  constructor(init?: Partial<BaseChecklistParameter>) {
    super();
    Object.assign(this, init);
  }
}

export class ChecklistParameter extends BaseChecklistParameter {
  /**
   * Check list operation Id
   * @type {string}
   */
  public checklistOperationId?: string;

  constructor(init?: Partial<ChecklistParameter>) {
    super();
    Object.assign(this, init);
  }
}

export class WorkOrderChecklist extends BaseChecklist {
  /**
   * A list of Work Order checklist operations
   * @type {WorkOrderChecklistOperation[]}
   */
  public workOrderChecklistOperations?: WorkOrderChecklistOperation[];
  /**
   * Work Order Id
   * @type {string}
   */
  public workOrderId?: string;

  /**
   * Allow adding other operations
   * @type {boolean}
   */
  public allowAdditionalOperations?: boolean;

  /**
   * Constructor
   * @param {Partial<WorkOrderChecklist>} init
   */
  constructor(init?: Partial<WorkOrderChecklist>) {
    super();
    Object.assign(this, init);
  }
}

export class WorkOrderChecklistOperation extends BaseChecklistOperation {
  /**
   *  A list of Work Order Checklist Parameters
   * @type {WorkOrderChecklistParameter[] | null}
   */
  public workOrderChecklistParameters?: WorkOrderChecklistParameter[] | null;
  /**
   * Work Order Checklist Id
   * @type {string}
   */
  public workOrderChecklistId?: string;
  /**
   * A list of Work Order Checklist Operations children
   * @type {WorkOrderChecklistOperation[]}
   */
  public workOrderChecklistOperations?: WorkOrderChecklistOperation[]; //Children
  /**
   *  parent work order checklist operation id
   * @type {string | null}
   */
  public workOrderChecklistOperationId?: string | null; //parent
  /**
   * A list of Work Order Checklist operation notes
   * @type {WorkOrderChecklistOperationNote[]}
   */
  public workOrderChecklistOperationNotes?: WorkOrderChecklistOperationNote[];
  /**
   * The state of operation value (closed or open)
   * @type {string | OperationValueStates}
   */
  public operationValueStateId?: string | OperationValueStates =
    /**
     *
     * @type {string | OperationValueStates}
     */
    OperationValueStates.Open;
  /**
   * Resource Id
   * @type {string | null}
   */
  public resourceId?: string | null;

  /*
  /!**
   * Todo: A Implementar no futuro
   * @type {WorkOrderChecklistOperationTimer[]}
   *!/
  public workOrderChecklistOperationTimer?:WorkOrderChecklistOperationTimer[];*/

  /**
   * Constructor
   * @param {Partial<WorkOrderChecklistOperation>} init
   */
  constructor(init?: Partial<WorkOrderChecklistOperation>) {
    super();
    Object.assign(this, init);
  }
}

export class WorkOrderChecklistParameter extends BaseChecklistParameter {
  /**
   * Work order checklist operation id
   * @type {string}
   */
  public workOrderChecklistOperationId?: string;
  /**
   * Value of operation
   * @type {string}
   */
  public value?: string;
  /**
   * Resource Id
   * @type {string}
   */
  public resourceId?: string;

  constructor(init?: Partial<WorkOrderChecklistParameter>) {
    super();
    Object.assign(this, init);
  }
}

/**
 *  Operation Group Model
 */
export class OperationGroup extends BaseNote {
  /**
   * description Operation Group
   * @type {string}
   */
  description: string;

  /**
   * order Operation Group
   * @type {string}
   */
  order: number;

  /**
   * Constructor
   * @param {Partial<OperationGroup>} init
   */
  constructor(init?: Partial<OperationGroup>) {
    super();
    Object.assign(this, init);
  }
}

export enum OperationValueStates {
  Closed = 'operationvaluestates.closed',
  Open = 'operationvaluestates.open',
}

export enum ParameterDataTypes {
  Int = 'parameterdatatypes.int',
  Decimal = 'parameterdatatypes.decimal',
  String = 'parameterdatatypes.string',
}

export enum ParameterFieldTypes {
  SelectButton = 'parameterfieldtypes.selectbutton',
  InputNumber = 'parameterfieldtypes.inputnumber',
}

export enum WorkOrderTypes {
  Preventive = 'workordertypes.preventive',
  Corrective = 'workordertypes.corrective',
}
