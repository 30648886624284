import { Injectable } from '@angular/core';
import {
  AbstractAuthService,
  ApiGenericService,
  InterceptorConfig,
  UtilityService,
} from '@prg/prg-core-lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { RoleNames } from '../../../Utilities/helpers';
import { Department } from '../../models/department.model';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  private static DepartmentUrl = 'department';
  /**
   * Current state of SideBar
   */
  private userDepartmentsSubject = new BehaviorSubject<Department[]>(null);
  constructor(
    private apiGenericService: ApiGenericService,
    private utilityService: UtilityService,
    private authService: AbstractAuthService
  ) {}

  /**
   * Setter for userDepartmentsSubject
   * @param value
   */
  public setUserDepartments(value: Department[]): void {
    this.userDepartmentsSubject.next(value);
  }

  /**
   * Getter for userDepartmentsSubject
   *
   * @returns The userDepartmentsSubject as an observable
   */
  public getUserDepartmentsObservable(): Observable<Department[]> {
    return this.userDepartmentsSubject.asObservable();
  }

  /**
   * Getter for userDepartmentsSubject
   *
   * @returns The userDepartmentsSubject current value
   */
  public getUserDepartmentsValue(): Department[] {
    return this.userDepartmentsSubject.getValue();
  }

  public getDepartmentsByUserIdAsync(userId: string): Promise<Department[]> {
    return new Promise<Department[]>(async (resolve, reject) => {
      const response = await this.apiGenericService.get(
        this.utilityService.normalizeUrl(
          DepartmentService.DepartmentUrl,
          'getDepartmentsByUserId'
        ),
        userId,
        new InterceptorConfig({
          apiRequest: true,
          handleLoading: true,
          handleErrors: true,
        })
      );

      resolve(response.entity);
    });
  }

  public currentUserIsDepartmentManager() {
    const user = this.authService.getLoggedUser();
    return (
      user != null &&
      user.workspaceTokens.token.role.includes(RoleNames.DepartmentManager) &&
      !user.workspaceTokens.token.role.includes(RoleNames.Manager)
    );
  }
}
