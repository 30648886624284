import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ArrayUtilityService,
  ObjectsUtilityService,
  PageContextService,
  User,
} from '@prg/prg-core-lib';
import { Subscription } from 'rxjs';
import { Resource } from '../../../../../resources/Models/resource';
import { WorkOrder } from '../../../../../work-orders/models/work-order-model';
import { WorkOrderEntitiesOperationNames } from '../../model/work-order-entities-operation-names-enum';
import { WorkOrderService } from '../../services/work-order.service';

@Component({
  selector: 'app-work-order-info',
  templateUrl: './work-order-info.page.html',
  styleUrls: ['./work-order-info.page.scss'],
})
/**
 * Page Work Order Info
 */
export class WorkOrderInfoPage implements OnInit, OnDestroy {
  /**
   * Worker order id
   * @type {string}
   * @private
   */
  private workOrderId: string;

  /**
   * Current Work order
   * @type {WorkOrder}
   * @private
   */
  public workOrder: WorkOrder;

  /**
   * Park Name
   * @type {string}
   */
  public park: Resource;

  /**
   * Work Order users
   * @type {User[]}
   */
  public users: User[] = [];

  /**
   * Base translation path for the fields of work order entity in assets
   * @type {string}
   */
  public entityFieldsTranslationPath: string = 'entities.workorder.fields.';

  /**
   * To clean Observables
   * @type {Subscription[]}
   */
  public subscription: Subscription[] = [];

  /**
   * Constructor
   * @param {WorkOrderService} workOrderService
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ActivatedRoute} activatedRoute
   * @param arrayUtilityService
   * @param router
   * @param pageContextService
   * @param sanitizer
   */
  constructor(
    private workOrderService: WorkOrderService,
    private objectsUtilityService: ObjectsUtilityService,
    private activatedRoute: ActivatedRoute,
    private arrayUtilityService: ArrayUtilityService,
    private router: Router,
    private pageContextService: PageContextService,
    private sanitizer: DomSanitizer
  ) {}

  /**
   * ngOnInit
   * @returns {Promise<void>}
   */
  public async ngOnInit(): Promise<void> {
    this.workOrderId = this.activatedRoute.snapshot.paramMap.get('workOrderId');
    if (this.workOrderId) {
      await this.getWorkOrderInfo();
      this.subscription.push(
        this.pageContextService.subscribeVariable(
          'workOrder',
          this.workOrderId,
          this.workOrderUpdate
        )
      );
    } else {
      /*await this.navigationService.backToPreviousRoute();*/
    }
  }

  /**
   * Get work order updated which was saved on cache
   * @param {WorkOrder} data
   */
  public workOrderUpdate = (data: WorkOrder) => {
    if (data != null) {
      this.workOrder.startDate = data?.startDate;
      this.workOrder.finishDate = data?.finishDate;
    }
  };

  /**
   * Method to get work Order Info
   * @returns {Promise<void>}
   */
  private async getWorkOrderInfo(): Promise<void> {
    try {
      const responseWorkOrder =
        await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
          'workorder',
          WorkOrderEntitiesOperationNames.ByIdWithResource,
          new WorkOrder({
            id: this.workOrderId,
          })
        );

      if (responseWorkOrder?.entity != null) {
        this.workOrder = this.objectsUtilityService.clone(
          responseWorkOrder?.entity
        );
        if (responseWorkOrder?.data?.resource != null) {
          this.park = this.objectsUtilityService.clone(
            responseWorkOrder.data.resource
          );
        }
        if (
          this.arrayUtilityService.isNotNullOrEmptyArray(
            this.workOrder.workOrderUsers
          )
        ) {
          this.users = this.workOrder.workOrderUsers.map(
            (workOrderUser) => workOrderUser.user
          );
        }
        if (this.workOrder.description != null) {
          this.workOrder.description = <string>(
            this.sanitizer.bypassSecurityTrustHtml(this.workOrder.description)
          );
        }
      }
    } catch (e) {
      /*await this.navigationService.backToPreviousRoute();*/
    }
  }

  /**
   * Navigate to worker user-profile
   * @param {string} id
   * @returns {Promise<void>}
   */
  public async navigateToUserProfile(id: string): Promise<void> {
    await this.router.navigate(['/user-profile', id]);
  }

  ngOnDestroy(): void {
    this.pageContextService.unsubscribe(...this.subscription);
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}
