import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import {
  ArrayUtilityService,
  ObjectsUtilityService,
  UtilityService,
} from '@prg/prg-core-lib';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { WorkOrder } from '../../../../../work-orders/models/work-order-model';
import { WorkOrderService } from '../../services/work-order.service';

@Component({
  selector: 'app-work-order-signature',
  templateUrl: './work-order-signature.component.html',
  styleUrls: ['./work-order-signature.component.scss'],
})
export class WorkOrderSignatureComponent implements OnInit, OnDestroy {
  /**
   * Work Order
   * @type {WorkOrder}
   */
  public workOrder: WorkOrder;

  /**
   * Show/Hide Component to create signature
   * @type {boolean}
   */
  public showSignatureCreation: boolean = false;

  /**
   * responsible Name
   * @type {string}
   */
  public responsibleName: string = null;

  /**
   * Constructor
   * @param {DynamicDialogRef} dynamicDialogRef
   * @param {DynamicDialogConfig} dynamicDialogConfig
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {UtilityService} utilityService
   * @param {WorkOrderService} workOrderService
   * @param elementRef
   */
  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private objectsUtilityService: ObjectsUtilityService,
    private arrayUtilityService: ArrayUtilityService,
    public utilityService: UtilityService,
    private workOrderService: WorkOrderService,
    private elementRef: ElementRef
  ) {}

  /**
   * ngOnInit
   */
  public ngOnInit(): void {
    this.workOrder = this.objectsUtilityService.clone(
      this.dynamicDialogConfig?.data['workOrder']
    );

    if (this.workOrder != null) {
      this.responsibleName = this.workOrder.responsibleSignatureName;
      if (this.workOrder.imageUrl == null) {
        this.showSignatureCreation = true;
      }
    }
  }

  /**
   * Save signature
   * @param {Blob} event
   * @returns {Promise<void>}
   */
  public async onSaveSignature(event: Blob): Promise<void> {
    if (event instanceof Blob) {
      try {
        const signature = await this.utilityService.getBase64(event);

        if (signature != null) {
          this.workOrder.imageUrl = signature;
          this.workOrder.responsibleSignatureName = this.responsibleName;
        }
        const responseSignature =
          await this.workOrderService.createWorkOrderSignature(this.workOrder);

        this.elementRef.nativeElement
          .querySelector('image-drawing .toolbar .buttons a:last-child')
          .click();
        /*  (<HTMLElement>document.querySelector('image-drawing .toolbar .buttons a:last-child')).click()*/

        if (responseSignature != null && responseSignature?.entity != null) {
          this.workOrder = this.objectsUtilityService.clone(
            responseSignature?.entity
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  public async saveResponsible() {
    this.workOrder.responsibleSignatureName = this.responsibleName;
    const responseSignature =
      await this.workOrderService.createWorkOrderSignature(this.workOrder);

    if (responseSignature != null && responseSignature?.entity != null) {
      this.workOrder = this.objectsUtilityService.clone(
        responseSignature?.entity
      );
    }
  }

  /**
   * Show signature creation component to resign
   */
  public reSign(): void {
    this.showSignatureCreation = true;
  }

  /**
   * Handle action whe user click on cancel button
   */
  public onCancel(): void {
    if (this.workOrder.imageUrl != null) {
      this.showSignatureCreation = false;
    } else {
      this.dynamicDialogRef.close(this.workOrder);
    }
  }

  /**
   * ng On destroy
   */
  ngOnDestroy(): void {
    this.dynamicDialogRef.close(this.workOrder);
  }
}
