import { Component, OnInit } from '@angular/core';
import {
  AbstractAuthService,
  AbstractEntityTypeService,
  ArrayUtilityService,
  AuthGuard,
  BaseAction,
  BaseActionKey,
  BaseField,
  DynamicFormActionOutput,
  DynamicFormContextService,
  EntityType,
  ObjectsUtilityService,
  UtilityService,
  ViewMode,
} from '@prg/prg-core-lib';
import { DepartmentService } from '../../../Core/services/department-service/department.service';
import { ChecklistOperation, WorkOrder } from '../../models/work-order-model';
import { WorkOrderOperationsService } from '../../services/work-order-operations.service';
import { OPERATIONS_DYNAMIC_FORM } from './work-order-operations.configs';

@Component({
  selector: 'app-work-order-operations',
  templateUrl: './work-order-operations.component.html',
  styleUrls: ['./work-order-operations.component.scss'],
})
export class WorkOrderOperationsComponent implements OnInit {
  /** List of operations for this workorder  */
  public operations: ChecklistOperation[] = [];
  /** The selected operation */
  public selectedOperation: any[] = [];

  /** diable list when on e*/
  public disabledList: boolean = false;

  /** The form fields to build dynamic form */
  public operationsDynFormFields: BaseField[] = [];
  /** The form actions to build dynamic form */
  public operationsDynFormActions: BaseAction[] =
    this.arrayUtilityService.clone(OPERATIONS_DYNAMIC_FORM.actions);

  /**
   * The view mode type of the form. Default is "read"
   * @type {ViewMode}
   */
  public viewMode: ViewMode = ViewMode.Add;

  /** debounce time */
  private updateDebounceTime: number = 200;
  /** current number of items used to the order field*/
  private currentNumberOfItems = 0;

  /** control variable to make the html wait for the necessary changes in the fields  */
  public allFieldsAreUpdated: boolean = false;

  //private workOrderAux: WorkOrder;
  private _workOrder: WorkOrder = null;
  private entityType: EntityType = null;

  // /** The workorder */
  // @Input('workOrder') set workOrder(workOrder: WorkOrder) {
  //   this._workOrder = workOrder;
  //   // if (this._workOrder != null) {
  //   //   //this.updateResourcesTreeSelectParentId();
  //   // }
  // }
  public canReorderElements: boolean = false;
  get workOrder(): WorkOrder {
    return this._workOrder;
  }
  /** Constructor, just gets the required services from DI */
  constructor(
    private arrayUtilityService: ArrayUtilityService,
    private utilityService: UtilityService,
    private workorderOperationsService: WorkOrderOperationsService,
    private objectsUtilityService: ObjectsUtilityService,
    private dynamicFormContextService: DynamicFormContextService,
    private entityTypeService: AbstractEntityTypeService,
    private authGuard: AuthGuard,
    private departmentService: DepartmentService,
    private authService: AbstractAuthService
  ) {
    this.canReorderElements = this.authGuard.isGranted(
      'reorderoperations',
      'checklistoperation'
    );
  }

  /**
   * Gets the list of operations from api and sets the number of items
   */
  async ngOnInit(): Promise<void> {
    this.entityType = await this.entityTypeService.getAllEntityTypeDataByName(
      'checklistoperation'
    );

    this._workOrder = await this.dynamicFormContextService.getVariableValue(
      'workOrder',
      'entity'
    );

    this.updateResourcesTreeSelectParentId();
    this.operationsDynFormFields =
      this.utilityService.getBaseFieldsFromEntityType(this.entityType);

    if (this.departmentService.currentUserIsDepartmentManager()) {
      if (
        this._workOrder.id != null &&
        this._workOrder.createdBy != null &&
        this.authService.getLoggedUser().id != this._workOrder.createdBy
      ) {
        this.operationsDynFormActions = [];
        this.canReorderElements = false;
      }
    }

    this.getOperations();
  }

  private getOperations(): void {
    if (this.workOrder != null) {
      this.operations =
        this.workOrder.otherOperations != null
          ? this.workOrder.otherOperations
          : [];
    } else {
      this.operations = [];
    }

    this.operations = this.arrayUtilityService.sortByProperty(
      this.operations,
      'order'
    );

    this.currentNumberOfItems = this.operations.length;
  }

  /**
   * this function receives the event
   * when a new element is selected in the list
   * @param event
   */
  public onSelectionChanged(event: any): void {
    if (event.value == null) {
      return;
    }
    if (event.value.length === 0) {
      this.clearSelection();
    } else {
      this.selectedOperation = [event.value[event.value.length - 1]];
    }
    this.setViewMode(ViewMode.Read);
  }

  /**
   * this function reorders the elements in the database when they have their order changed
   */
  public reorderElements = this.utilityService.debounce(() => {
    if (this.operations.every((x) => x.id != null)) {
      const mapElements = new Map<string, number>();

      for (let i = 0; i < this.operations.length; i++) {
        this.operations[i].order = i;
        // { order: this.elements[i].order }
        mapElements.set(this.operations[i].id, this.operations[i].order);
      }
      this.workorderOperationsService.reorderOperationsAsync(mapElements);
    } else {
      for (let i = 0; i < this.operations.length; i++) {
        this.operations[i].order = i;
      }
      this.workOrder.otherOperations = this.operations;
      this.dynamicFormContextService.setVariableData(
        'workOrder',
        'entity',
        this.workOrder
      );
    }
  }, this.updateDebounceTime);

  /**
   * This function handles the actions from the dynamic form
   * @param {DynamicFormActionOutput} event
   * @returns {Promise<void>}
   */
  public async onExecutedAction(event: DynamicFormActionOutput): Promise<void> {
    const operation: ChecklistOperation =
      this.selectedOperation[this.selectedOperation.length - 1];

    let savedOperation: ChecklistOperation;
    switch (event.action) {
      case BaseActionKey.Add:
        this.addNewOperation(this.createNewOperation());
        this.setViewMode(ViewMode.Edit);
        break;
      case BaseActionKey.Save:
        if (this.workOrder.id == null) {
          if (this.workOrder.otherOperations != null) {
            this.workOrder.otherOperations.push(event.formEntity);
          } else {
            this.workOrder.otherOperations = [event.formEntity];
          }
          this.replaceOperationOnTheList(operation, event.formEntity);
        } else {
          event.formEntity['workOrderId'] = this.workOrder.id;
          savedOperation = await this.workorderOperationsService.saveAsync(
            event.formEntity
          );
          Object.keys(savedOperation).forEach(
            (key) => (operation[key] = savedOperation[key])
          );
          this.replaceOperationOnTheList(operation, savedOperation);
          if (this.workOrder.otherOperations != null) {
            this.workOrder.otherOperations.push(savedOperation);
          } else {
            this.workOrder.otherOperations = [savedOperation];
          }
        }

        this.setViewMode(ViewMode.Read);
        break;
      case BaseActionKey.Update: {
        event.formEntity['workOrderId'] = this.workOrder.id;

        savedOperation = await this.workorderOperationsService.saveAsync(
          event.formEntity
        );
        Object.keys(savedOperation).forEach(
          (key) => (operation[key] = savedOperation[key])
        );
        this.replaceOperationOnTheList(operation, savedOperation);
        const indexToUpdate = this.workOrder.otherOperations.findIndex(
          (x) => x.id == operation.id
        );
        if (indexToUpdate != -1) {
          this.workOrder.otherOperations[indexToUpdate] = savedOperation;
        }
        this.setViewMode(ViewMode.Read);
        break;
      }

      case BaseActionKey.Create:
        if (this.workOrder.id == null) {
          if (this.workOrder.otherOperations != null) {
            this.workOrder.otherOperations.push(event.formEntity);
          } else {
            this.workOrder.otherOperations = [event.formEntity];
          }
          this.viewMode = ViewMode.Read;
        }
        break;
      case BaseActionKey.Cancel:
        if (operation.id == null || !operation.id.length) {
          this.removeOperationFromList(operation);
          this.setViewMode(ViewMode.Add);
        } else {
          this.replaceOperationOnTheList(
            operation,
            this.objectsUtilityService.clone(operation)
          );
          this.setViewMode(ViewMode.Read);
        }
        break;

      case BaseActionKey.Edit:
        this.setViewMode(ViewMode.Edit);
        break;
      case BaseActionKey.Delete:
        if (operation.id != null && operation.id.length > 0) {
          await this.workorderOperationsService.deleteAsync(operation);
          const indexToDelete = this.workOrder.otherOperations.findIndex(
            (x) => x.id == operation.id
          );
          if (indexToDelete != -1) {
            this.workOrder.otherOperations.splice(indexToDelete, 1);
          }
        }
        this.removeOperationFromList(operation);
        this.setViewMode(ViewMode.Add);
        break;

      default:
        break;
    }

    this.dynamicFormContextService.setVariableData(
      'workOrder',
      'entity',
      this.workOrder
    );
  }

  private updateOperationsGuiSettings() {
    const createOperation = this.entityType.operations.find(
      (o) => o.name == 'create'
    );
    if (createOperation != null && createOperation.guiSettings != null) {
      createOperation.guiSettings =
        this.utilityService.updateGuiSettingsProperty(
          createOperation.guiSettings,
          'isGuiOperation',
          true
        );
    }
  }

  /**
   * This function removes an element from the list
   * @param {WorkOrder} operation
   */
  private removeOperationFromList(operation: ChecklistOperation): void {
    const index = this.operations.indexOf(operation);
    this.operations.splice(index, 1);
    this.clearSelection();
  }

  /**
   * This function removes an element from the list
   * // TODO: PL: candidate to utilities array service
   * @param operationToReplace
   * @param replacement
   */
  private replaceOperationOnTheList(
    operationToReplace: ChecklistOperation,
    replacement: ChecklistOperation
  ): void {
    const index = this.operations.indexOf(operationToReplace);
    this.operations.splice(index, 1, replacement);
    this.selectedOperation = [replacement];
  }

  /**
   * This function creates a new operation and set its order
   * @returns {ChecklistOperation}
   */
  public createNewOperation(): ChecklistOperation {
    return new ChecklistOperation({
      order: this.currentNumberOfItems++,
    });
  }

  /**
   * This function adds an element to the list
   * @param {ChecklistOperation} operation
   */
  public addNewOperation(ChecklistOperation: ChecklistOperation): void {
    this.operations.push(ChecklistOperation);
    this.selectedOperation = [this.operations[this.operations.length - 1]];
  }

  /**
   * This function sets the view mode and disables the list if in edit mode
   * @param {ViewMode} newMode
   */
  private setViewMode(newMode: ViewMode) {
    this.viewMode = newMode;
    this.disabledList = newMode == ViewMode.Edit;
  }

  /** Clears the list selection */
  private clearSelection() {
    this.selectedOperation = [];
  }

  /**update resources tree parent id */
  private updateResourcesTreeSelectParentId(): void {
    const treeSelectResouceField = this.entityType.properties.find(
      (field) => field.name === 'resourceId'
    );

    if (
      treeSelectResouceField != null &&
      treeSelectResouceField.guiSettings != null
    ) {
      treeSelectResouceField.guiSettings =
        this.utilityService.updateGuiSettingsProperty(
          treeSelectResouceField.guiSettings,
          'resourceParentId',
          this.workOrder.resourceId
        );
    }

    this.allFieldsAreUpdated = true;
  }
}
