import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ConsumableFormPage} from './components/consumable-form/consumable-form.page';
import {WorkOrderInfoPage} from './components/work-order-info/work-order-info.page';
import {WorkOrderNotesPage} from './components/work-order-notes/work-order-notes.page';

const routes: Routes = [
  {
    path: 'checklist',
    loadChildren: () =>
      import('../work-order-checklist/work-order-checklist.module').then(
        (m) => m.WorkOrderChecklistModule
      ),
  },
  {
    path: 'park',
    loadChildren: () =>
      import('../resource/resource.module').then((m) => m.ResourceModule),
  },

  {
    path: 'consumables',
    component: ConsumableFormPage,
  },

  {
    path: 'info',
    component: WorkOrderInfoPage,
  },

  {
    path: 'notes',
    component: WorkOrderNotesPage,
  },

  {
    path: '',
    redirectTo: 'park',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WorkOrderRoutingModule {}
