import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ArrayUtilityService,
  BaseNote,
  EditingNoteActions,
  EditNotes,
  Filter,
  FilterGroup,
  FilterOperations,
  ObjectsUtilityService,
} from '@prg/prg-core-lib';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  WorkOrderChecklistOperation,
  WorkOrderChecklistOperationNote,
} from '../../../../../work-orders/models/work-order-model';

import { WorkOrderChecklistEntitiesOperationNames } from '../../model/work-order-checklist-entities-operation-names-enum';
import { WorkOrderChecklistService } from '../../services/work-order-checklist.service';

@Component({
  selector: 'app-checklist-obs',
  templateUrl: './work-order-checklist-obs.page.html',
  styleUrls: ['./work-order-checklist-obs.page.scss'],
})
/**
 * Component Pop-up Work Order Checklist Obs
 */
export class WorkOrderChecklistObsPage implements OnInit, OnDestroy {
  /**
   * Current Work order checklist operation
   * @type {WorkOrderChecklistOperation}
   */
  public workOrderChecklistOperation: WorkOrderChecklistOperation;
  /**
   *  Work order checklist operation id
   * @type {string}
   */
  public workOrderChecklistOperationId: string;
  /**
   *  A list of all notes for this  Work order checklist operation
   * @type {WorkOrderChecklistOperationNote[]}
   */
  public workOrderChecklistOperationNotes: WorkOrderChecklistOperationNote[] =
    [];
  /**
   * The name of field parent to sent it to base note chips component
   * @type {string}
   */
  public fieldParent: string = 'workOrderChecklistOperationId';

  /**
   * Constructor
   * @param {DynamicDialogRef} dynamicDialogRef
   * @param {DynamicDialogConfig} dynamicDialogConfig
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {WorkOrderChecklistService} checklistService
   */
  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private objectsUtilityService: ObjectsUtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private checklistService: WorkOrderChecklistService
  ) {}
  /**
   * ngOnDestroy
   */
  public ngOnDestroy(): void {
    this.dynamicDialogRef.close(this.workOrderChecklistOperationNotes);
  }
  /**
   * ngOnInit
   */
  public ngOnInit(): void {
    this.workOrderChecklistOperation = this.objectsUtilityService.clone(
      this.dynamicDialogConfig?.data['checklistOperation']
    );

    if (this.workOrderChecklistOperation != null) {
      this.workOrderChecklistOperationId = this.workOrderChecklistOperation?.id;

      this.getNotesByWorkOrderChecklistOperationIdAsync();
    }
  }

  /**
   * Method that gets notes for this work order checklist operation
   * @returns {Promise<void>}
   */
  private async getNotesByWorkOrderChecklistOperationIdAsync() {
    try {
      const responseOperationNotes =
        await this.checklistService.manageWorkOrderChecklistDataByEntityServiceAsync(
          'workorderchecklistoperationnote',
          WorkOrderChecklistEntitiesOperationNames.Filtered,
          new FilterGroup({
            filterCollections: [
              new Filter({
                startGroup: true,
                propertyName: 'WorkOrderChecklistOperationId',
                filterOperation: FilterOperations.EqualTo,
                value: this.workOrderChecklistOperationId,
              }),
            ],
          })
        );
      if (
        this.arrayUtilityService.isNotNullOrEmptyArray(
          responseOperationNotes?.entity
        )
      ) {
        this.workOrderChecklistOperationNotes = this.arrayUtilityService.clone(
          responseOperationNotes?.entity
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Method that saves a new obs
   * @param {BaseNote} event
   * @returns {Promise<void>}
   */
  public async onHandleNewObs(event: BaseNote): Promise<void> {
    try {
      const newNoteResponse =
        await this.checklistService.manageWorkOrderChecklistDataByEntityServiceAsync(
          'workorderchecklistoperationnote',
          WorkOrderChecklistEntitiesOperationNames.Create,
          event
        );

      if (newNoteResponse?.entity != null) {
        this.workOrderChecklistOperationNotes.push(newNoteResponse.entity);
        event.id = newNoteResponse.entity.id;
      }
      /* this.workOrderChecklistOperationNotes = this.objectsUtilityService.clone(
         this.workOrderChecklistOperationNotes
       );*/
    } catch (e) {
      console.log(e);
    }
  }
  /**
   * Method that saves an edited obs
   * @param {EditNotes} event
   * @returns {Promise<void>}
   */
  public async onHandleEditObs(event: EditNotes): Promise<void> {
    try {
      const index = this.workOrderChecklistOperationNotes.findIndex(
        (obs) => obs.id === event.note.id
      );
      if (event.typeEditing == EditingNoteActions.Editing) {
        const editObsResponse =
          await this.checklistService.manageWorkOrderChecklistDataByEntityServiceAsync(
            'workorderchecklistoperationnote',
            WorkOrderChecklistEntitiesOperationNames.Update,
            event.note
          );

        if (editObsResponse != null) {
          this.workOrderChecklistOperationNotes[index] =
            this.objectsUtilityService.clone(event.note);
        } else {
          this.workOrderChecklistOperationNotes =
            this.arrayUtilityService.clone(
              this.workOrderChecklistOperationNotes
            );
        }
      } else {
        await this.checklistService.manageWorkOrderChecklistDataByEntityServiceAsync(
          'workorderchecklistoperationnote',
          WorkOrderChecklistEntitiesOperationNames.Delete,
          event.note
        );
        this.workOrderChecklistOperationNotes.splice(index, 1);
      }
    } catch (e) {
      this.workOrderChecklistOperationNotes = this.arrayUtilityService.clone(
        this.workOrderChecklistOperationNotes
      );
    }
  }
}
