import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ImageDrawingModule } from 'ngx-image-drawing';
import {
  PrgChatModule,
  PrgDashboardModule,
  PrgSharedComponentsModule,
  SecurePipe,
} from '@prg/prg-core-lib';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ImageModule } from 'primeng/image';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RippleModule } from 'primeng/ripple';
import { SpeedDialModule } from 'primeng/speeddial';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { WorkOrderChecklistModule } from '../work-order-checklist/work-order-checklist.module';
import { ConsumableFormPage } from './components/consumable-form/consumable-form.page';
import { WorkOrderInfoPage } from './components/work-order-info/work-order-info.page';
import { WorkOrderNotesPage } from './components/work-order-notes/work-order-notes.page';
import { WorkOrderPanelPage } from './components/work-order-panel/work-order-panel.page';
import { WorkOrderSignatureComponent } from './components/work-order-signature/work-order-signature.component';
import { WorkOrderTimersComponent } from './components/work-order-timers/work-order-timers.component';
import { WorkOrderRoutingModule } from './work-order-routing.module';

@NgModule({
  declarations: [
    ConsumableFormPage,
    WorkOrderInfoPage,
    WorkOrderNotesPage,
    WorkOrderPanelPage,
    WorkOrderSignatureComponent,
    WorkOrderTimersComponent,
  ],
  imports: [
    SecurePipe,
    WorkOrderRoutingModule,
    PrgDashboardModule,
    CommonModule,
    DataViewModule,
    ToolbarModule,
    ToggleButtonModule,
    FormsModule,
    DropdownModule,
    IonicModule,
    ButtonModule,
    TranslateModule,
    ReactiveFormsModule,
    TableModule,
    RippleModule,
    InputTextareaModule,
    DialogModule,
    WorkOrderChecklistModule,
    AutoCompleteModule,
    InputNumberModule,
    TagModule,
    TooltipModule,
    InputTextModule,
    PrgChatModule,
    SpeedDialModule,
    ImageDrawingModule,
    ImageModule,
    ToastModule,
    ConfirmDialogModule,
    SplitButtonModule,
    PrgSharedComponentsModule,
  ],
  exports: [WorkOrderPanelPage],
})
export class WorkOrderModule {}
