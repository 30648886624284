import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  ArrayUtilityService,
  AuthGuard,
  BaseColumn,
  Filter,
  FilterGroup,
  FilterOperations,
  FormGroupService,
  NotificationsService,
  ObjectsUtilityService,
  PageContextService,
  UtilityService,
} from '@prg/prg-core-lib';
import { Subscription } from 'rxjs';
import { ParkSpare } from '../../../../../resources/Models/park-spare.model';
import {
  Consumable,
  UsedConsumableSources,
  WorkOrderConsumable,
} from '../../../../../work-orders/models/consumable-model';
import {
  WorkOrder,
  WorkOrderStates,
} from '../../../../../work-orders/models/work-order-model';
import { ResourceEntitiesOperationNames } from '../../../resource/model/Resource-entities-operation-names-enum';
import { ResourceService } from '../../../resource/services/resource.service';
import { WorkOrderEntitiesOperationNames } from '../../model/work-order-entities-operation-names-enum';
import { WorkOrderService } from '../../services/work-order.service';
import { ConsumableColumns } from './consumable-table.model';

@Component({
  selector: 'app-consumable-form',
  templateUrl: './consumable-form.page.html',
  styleUrls: ['./consumable-form.page.scss'],
})
export class ConsumableFormPage implements OnInit, OnDestroy {
  /**
   * Consumable table columns to build the consumable form
   */
  public consumableColumns: BaseColumn[] = ConsumableColumns;

  /**
   * Property with type Form Group which bind to the consumable form
   */
  public formConsumable: FormGroup;

  /**
   * A form array that belongs to the consumable form
   */
  public consumableArrayForm = new FormArray([]);

  /**
   * Worker order id
   * @type {string}
   * @private
   */
  private workOrderId: string;
  /**
   * Work Order
   * @type {string}
   * @private
   */
  public workOrder: WorkOrder;
  /**
   * A list of consumable options
   * @type {Consumable[]}
   */
  public consumablesOptions: (ParkSpare | Consumable)[];
  /**
   * A list of consumables used in this work order
   * @type {WorkOrderConsumable[]}
   * @private
   */
  private consumablesWorkOrder: WorkOrderConsumable[] = [];

  /**
   * Defines if there is already consumables data associated to this work order.If so edit mode is true
   * @type {boolean}
   */
  private editMode = false;

  /**
   * This property define if component is loading data from an existing query.
   *
   * Default is true.
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The state of work order
   * @type {WorkOrderStates}
   */
  public workOrderState: WorkOrderStates = WorkOrderStates.Scheduled;

  /**
   * To clean Observables
   * @type {Subscription[]}
   */
  public subscription: Subscription;

  /**
   * Base translation path for the page in assets
   * @type {string}
   */
  public pageTranslationPath: string =
    'pages-techparts-solar-mobile.consumable-form.';

  /**
   * park Id
   * @type {string}
   * @private
   */
  private parkId: string;

  /**
   * Constructor
   * @param {FormGroupService} formGroupService
   * @param {ActivatedRoute} activatedRoute
   * @param {WorkOrderService} workOrderService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {UtilityService} utilityService
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param notificationsService
   * @param pageContextService
   * @param resourceService
   * @param authGuard
   */
  constructor(
    private formGroupService: FormGroupService,
    private activatedRoute: ActivatedRoute,
    private workOrderService: WorkOrderService,
    private arrayUtilityService: ArrayUtilityService,
    private utilityService: UtilityService,
    private objectsUtilityService: ObjectsUtilityService,
    private notificationsService: NotificationsService,
    private pageContextService: PageContextService,
    private resourceService: ResourceService,
    private authGuard: AuthGuard
  ) {}

  /**
   * ngOnInit
   * @returns {Promise<void>}
   */
  async ngOnInit(): Promise<void> {
    this.workOrderId = this.activatedRoute.snapshot.paramMap.get('workOrderId');
    if (this.workOrderId) {
      this.subscription = this.pageContextService.subscribeVariable(
        'workOrder',
        this.workOrderId,
        this.workOrderUpdate
      );
      await this.getConsumablesWorkOrderData();
      await this.getConsumablesOptions();
      await this.initConsumableForm();
      if (this.workOrderState == WorkOrderStates.OnGoing) {
        this.formConsumable?.enable();
      } else {
        this.formConsumable?.disable();
      }
      if (
        this.workOrderState != WorkOrderStates.Scheduled &&
        this.authGuard.isGranted('workorder:change-on-complete', null, null)
      ) {
        this.formConsumable.enable();
      }

      this.isLoading = false;
    }
  }

  public workOrderUpdate = (data) => {
    this.workOrder = this.objectsUtilityService.clone(data);
    this.parkId = data?.resourceId;
    this.workOrderState = <WorkOrderStates>this.workOrder?.workOrderStateId;
    if (this.workOrderState == WorkOrderStates.OnGoing) {
      this.formConsumable?.enable();
    } else {
      this.formConsumable?.disable();
    }
    if (
      this.workOrderState != WorkOrderStates.Scheduled &&
      this.authGuard.isGranted('workorder:change-on-complete', null, null)
    ) {
      this.formConsumable.enable();
    }
  };

  /**
   * Method to get consumables data
   * @returns {Promise<void>}
   */
  private async getConsumablesWorkOrderData(): Promise<void> {
    try {
      const responseConsumablesWorkOrder =
        await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
          'workorderconsumable',
          WorkOrderEntitiesOperationNames.Filtered,
          new FilterGroup({
            filterCollections: [
              new Filter({
                startGroup: true,
                propertyName: 'WorkOrderId',
                filterOperation: FilterOperations.EqualTo,
                value: this.workOrderId,
              }),
            ],
          })
        );
      if (
        this.arrayUtilityService.isNotNullOrEmptyArray(
          responseConsumablesWorkOrder?.entity
        )
      ) {
        this.consumablesWorkOrder = this.arrayUtilityService.clone(
          responseConsumablesWorkOrder?.entity
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Method that gets all the available consumable options
   * @returns {Promise<void>}
   * @private
   */
  private async getConsumablesOptions() {
    if (this.parkId == null) {
      await this.getWorkOrderInfo();
    }
    try {
      const responseConsumableOptions =
        await this.resourceService.manageResourceDataByEntityServiceAsync(
          'parkspare',
          ResourceEntitiesOperationNames.GeneralAndParkConsumables,
          {
            parkId: this.parkId,
          }
        );

      let consumablesPark: ParkSpare[] = [];
      let consumablesGeneral: Consumable[] = [];
      if (
        this.arrayUtilityService.isNotNullOrEmptyArray(
          responseConsumableOptions?.entity
        )
      ) {
        consumablesPark = await responseConsumableOptions?.entity.sort((a, b) =>
          a?.name > b?.name ? 1 : -1
        );
      }
      if (
        this.arrayUtilityService.isNotNullOrEmptyArray(
          responseConsumableOptions?.data?.consumables
        )
      ) {
        consumablesGeneral =
          await responseConsumableOptions?.data?.consumables.sort((a, b) =>
            a?.name > b?.name ? 1 : -1
          );
      }

      this.consumablesOptions = [...consumablesPark, ...consumablesGeneral];
      if (this.consumablesOptions.length > 0) {
        this.consumablesOptions.forEach((x) => {
          x['consumableId'] = x.id;
        });
      }
    } catch (e) {
      /*await this.navigationService.backToPreviousRoute();*/
    }
  }

  /**
   * Method to get work Order Info
   * @returns {Promise<void>}
   */
  private async getWorkOrderInfo(): Promise<void> {
    try {
      const responseWorkOrder =
        await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
          'workorder',
          WorkOrderEntitiesOperationNames.ById,
          new WorkOrder({
            id: this.workOrderId,
          })
        );

      if (responseWorkOrder?.entity != null) {
        this.workOrder = this.objectsUtilityService.clone(
          responseWorkOrder?.entity
        );
        this.parkId = this.workOrder?.resourceId;
      }
    } catch (e) {
      /*await this.navigationService.backToPreviousRoute();*/
    }
  }

  /**
   * Method that inits array form
   * @private
   */
  private initConsumableFormArray(): void {
    if (
      this.consumablesWorkOrder != null &&
      this.consumablesWorkOrder?.length > 0
    ) {
      this.editMode = true;
      const aux = this.formGroupService.toFormGroupMultipleObjects(
        this.consumablesWorkOrder,
        this.consumableColumns
      );

      this.consumablesWorkOrder.forEach((value, index) => {
        const consumableId = aux[index].get('consumableId').value;
        const consumable = this.consumablesOptions.find(
          (cons) => cons.id === consumableId
        );
        aux[index]
          ?.get('name')
          .setValue({ name: consumable?.name, consumableId: consumable.id });

        if (
          consumable.dataTypeId?.toLowerCase() ===
          'basedatatypes.number'.toLowerCase()
        ) {
          aux[index]
            .get('quantity')
            .addValidators([Validators.required, Validators.min(1)]);
        } else {
          aux[index].get('quantity').addValidators([Validators.required]);
        }
        this.consumableArrayForm.push(aux[index]);
      });
      this.onAddRow(this.consumablesWorkOrder.length - 1);
    } else {
      const newConsumable: Consumable = this.newConsumableObject();
      (<FormArray>this.consumableArrayForm).controls = [];
      const aux = this.formGroupService.toFormGroupOneObject(
        newConsumable,
        this.consumableColumns
      );
      this.consumableArrayForm.push(aux);
    }
  }

  /**
   * Method that inits form
   * @private
   */
  private initConsumableForm(): void {
    this.initConsumableFormArray();
    this.formConsumable = new FormGroup({
      consumables: this.consumableArrayForm,
    });
  }

  /**
   * A getter for the form array controls
   * @returns {any}
   */
  public get consumables(): any {
    return (<FormArray>this.formConsumable.get('consumables')).controls;
  }

  /**
   * Method that returns an object type WorkOrderConsumable
   * @returns {WorkOrderConsumable}
   * @private
   */
  private newConsumableObject(): WorkOrderConsumable {
    return new WorkOrderConsumable({
      id: null,
      createdBy: null,
      createdOn: null,
      dataTypeId: null,
      description: null,
      modifiedBy: null,
      modifiedOn: null,
      name: null,
      operationId: null,
      transactionId: null,
      unit: null,
      quantity: null,
      workOrderId: this.workOrderId,
      workspaceId: null,
      consumableId: null,
      usedConsumableSourceId: null,
    });
  }

  /**
   * Method that add a row to the form
   * @param index
   * @returns {Promise<void>}
   * @private
   */
  private onAddRow(index: any): void {
    const newConsumable = this.newConsumableObject();

    this.consumableArrayForm.insert(
      index + 1,
      this.formGroupService.toFormGroupOneObject(
        newConsumable,
        this.consumableColumns
      )
    );
  }

  /**
   * Method that deletes a row of the form
   * @param index
   * @returns {Promise<void>}
   * @private
   */
  private async onDeleteRow(index: any): Promise<void> {
    if (this.consumableArrayForm?.controls[index]?.value?.id != null) {
      if (
        await this.notificationsService.prgConfirmationService(
          this.pageTranslationPath + 'warning-delete'
        )
      ) {
        (<FormArray>this.formConsumable.get('consumables')).removeAt(index);
        await this.onSubmitConsumables(
          this.consumablesWorkOrder[index],
          WorkOrderEntitiesOperationNames.Delete,
          index
        );
      } else {
        if (
          this.arrayUtilityService.isNotNullOrEmptyArray(
            this.consumablesWorkOrder
          ) &&
          this.consumablesWorkOrder[index] != null
        ) {
          this.consumableArrayForm.controls[index].setValue(
            this.consumablesWorkOrder[index]
          );
        }
      }
      this.consumableArrayForm.updateValueAndValidity();
    }
  }

  /**
   * Method that reset the form
   */
  private onResetForm(): void {
    this.consumableArrayForm.clear();
    this.consumableArrayForm.reset();
    (<FormArray>this.consumableArrayForm).controls = [];
    this.initConsumableFormArray();
    this.consumableArrayForm.updateValueAndValidity();
  }

  /**
   * Method that handles actions whenever the consumable option changed
   * @param {Consumable} selectedOption
   * @param {number} i
   */
  public onChangeConsumable(selectedOption: Consumable, i: number): void {
    if (selectedOption != null) {
      if (
        selectedOption?.dataTypeId?.toLowerCase() ==
        'basedatatypes.number'.toLowerCase()
      ) {
        this.consumableArrayForm.controls[i]
          .get('quantity')
          .addValidators([Validators.required, Validators.min(1)]);
      } else {
        this.consumableArrayForm.controls[i]
          .get('quantity')
          .addValidators([Validators.required]);
      }
    }
    this.consumableArrayForm.controls[i].get('quantity').reset();
    this.consumableArrayForm.updateValueAndValidity();
  }

  /**
   * Method that handles actions to submit the form
   * @param {WorkOrderConsumable} workOrderConsumable
   * @param {WorkOrderEntitiesOperationNames} action
   * @param {number} index
   * @returns {Promise<void>}
   */
  private async onSubmitConsumables(
    workOrderConsumable: WorkOrderConsumable,
    action: WorkOrderEntitiesOperationNames,
    index: number
  ): Promise<void> {
    try {
      if (action == WorkOrderEntitiesOperationNames.Create) {
        if (
          workOrderConsumable != null &&
          workOrderConsumable?.name != null &&
          workOrderConsumable?.quantity != null
        ) {
          const consumableSelected = <any>(
            this.objectsUtilityService.clone(workOrderConsumable.name)
          );
          const auxConsumable = new WorkOrderConsumable({
            name: consumableSelected?.name,
            dataTypeId: consumableSelected?.dataTypeId,
            description: consumableSelected?.description,
            consumableId: consumableSelected?.id,
            quantity: workOrderConsumable.quantity,
            unit: consumableSelected?.unit,
            workOrderId: workOrderConsumable.workOrderId,
            usedConsumableSourceId:
              consumableSelected?.parkSpareTypeId == null
                ? UsedConsumableSources.General
                : UsedConsumableSources.Park,
          });
          const newWorkOrderConsumableResponse =
            await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
              'workorderconsumable',
              action,
              auxConsumable
            );

          if (newWorkOrderConsumableResponse?.entity != null) {
            this.consumablesWorkOrder[index] = this.objectsUtilityService.clone(
              newWorkOrderConsumableResponse?.entity
            );

            this.editMode = true;
          } else {
            this.consumablesWorkOrder = this.arrayUtilityService.clone(
              this.consumablesWorkOrder
            );
          }
          this.onResetForm();
        }
      } else if (
        workOrderConsumable?.id != null &&
        action == WorkOrderEntitiesOperationNames.Delete
      ) {
        await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
          'workorderconsumable',
          action,
          workOrderConsumable
        );
        this.consumablesWorkOrder.splice(index, 1);
        this.onResetForm();
      } else if (
        workOrderConsumable?.id != null &&
        action == WorkOrderEntitiesOperationNames.Update
      ) {
        const consumableSelected = <any>(
          this.consumablesOptions.find(
            (c) => c.id == workOrderConsumable.consumableId
          )
        );

        const auxConsumable = new WorkOrderConsumable({
          ...workOrderConsumable,
          name: consumableSelected?.name,
          dataTypeId: consumableSelected?.dataTypeId,
          description: consumableSelected?.description,
          consumableId: consumableSelected?.id,
          unit: consumableSelected?.unit,
          usedConsumableSourceId:
            consumableSelected?.parkSpareTypeId == null
              ? UsedConsumableSources.General
              : UsedConsumableSources.Park,
        });
        const editWorkOrderConsumableResponse =
          await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
            'workorderconsumable',
            action,
            auxConsumable
          );
        if (editWorkOrderConsumableResponse?.entity != null) {
          this.consumablesWorkOrder[index] = this.objectsUtilityService.clone(
            editWorkOrderConsumableResponse?.entity
          );

          this.editMode = true;
        } else {
          this.consumablesWorkOrder = this.arrayUtilityService.clone(
            this.consumablesWorkOrder
          );
        }
        this.onResetForm();
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Clear Consumable
   * @param i
   * @param {Consumable} selectedOption
   */
  public onClearConsumable(i: any): void {
    this.onDeleteRow(i);
  }

  /**
   * Debounced method onChanged
   * @type {(args?: any) => void}
   */
  //Todo: Check if this method is necessary
  public onInputQuantity = this.utilityService.debounce((event) => {
    const lengthArray = this.consumableArrayForm.length;
    if (
      this.consumableArrayForm.controls[event + 1] == null &&
      event != lengthArray - 1
    ) {
      this.onAddRow(event);
    }
  }, 200);

  /**
   * Method that handles configs with debounce before submit the form
   * @type {(args?: any) => void}
   */
  public onBeforeSubmit = this.utilityService.debounce(async (event: any) => {
    if (event?.event != null && event?.event > 0) {
      this.consumableArrayForm.controls[event?.index].value.quantity =
        event?.event;
      const action: WorkOrderEntitiesOperationNames =
        this.consumableArrayForm?.controls[event?.index]?.value?.id != null
          ? WorkOrderEntitiesOperationNames.Update
          : WorkOrderEntitiesOperationNames.Create;
      await this.onSubmitConsumables(
        this.consumableArrayForm?.controls[event?.index]?.value,
        action,
        event?.index
      );
    }
  }, 1000);

  ngOnDestroy(): void {
    this.pageContextService.unsubscribe(this.subscription);
  }
}
