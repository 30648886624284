import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractEntityTypeService,
  ArrayUtilityService,
  BaseCacheService,
  ObjectsUtilityService,
} from '@prg/prg-core-lib';
import {
  WorkOrderChecklist,
  WorkOrderChecklistOperation,
  WorkOrderChecklistParameter,
} from '../../../../work-orders/models/work-order-model';

import { WorkOrderChecklistEntitiesOperationNames } from '../model/work-order-checklist-entities-operation-names-enum';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderChecklistService extends BaseCacheService<WorkOrderChecklist> {
  constructor(
    private entityTypeService: AbstractEntityTypeService,
    private arrayUtilityService: ArrayUtilityService,
    private objectsUtility: ObjectsUtilityService,
    translateService: TranslateService
  ) {
    super(translateService, '');
  }

  /**
   * Manage Work Order Data through the entity service
   * @param  {string} entityName
   * @param {WorkOrderChecklistEntitiesOperationNames} operationName
   * @param {null} entity
   * @returns {Promise<any>}
   */
  public manageWorkOrderChecklistDataByEntityServiceAsync(
    entityName: string,
    operationName: WorkOrderChecklistEntitiesOperationNames,
    entity?: any
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const entityType =
          await this.entityTypeService.getAllEntityTypeDataByName(entityName);

        if (entityType != null) {
          const operationRequest = entityType.operations.find(
            (operation) => operation.name == operationName
          );

          if (operationRequest != null) {
            const entityRequest: any =
              await this.entityTypeService.executeAction(
                entityName,
                operationRequest,
                entity
              );
            resolve(entityRequest);
          }
        }
      } catch (e) {
        await reject(e);
      }
    });
  }

  /**
   * Split Values that are separated by a char into an array on Work Order Parameters Values
   * Order operations and parameters
   * @param {WorkOrderChecklistOperation[]} operations
   * @param {string} splitChar
   * @returns {WorkOrderChecklistOperation[]}
   */
  public splitValuesOptionsIntoArrayAndOrder(
    operations: WorkOrderChecklistOperation[],
    splitChar: string
  ) {
    if (this.arrayUtilityService.isNotNullOrEmptyArray(operations)) {
      operations.sort((a, b) => (a.order > b.order ? 1 : -1));
      operations.forEach((operation) => {
        if (
          this.arrayUtilityService.isNotNullOrEmptyArray(
            operation.workOrderChecklistParameters
          )
        ) {
          operation.workOrderChecklistParameters.sort((a, b) =>
            a.order > b.order ? 1 : -1
          );
          const parametersOrganized: WorkOrderChecklistParameter[] = [];
          operation.workOrderChecklistParameters.forEach((parameters) => {
            //Split close Values by splitchar
            if (
              parameters?.closeValues != null &&
              !Array.isArray(parameters.closeValues)
            ) {
              parameters.closeValues = (<string>(
                parameters['closeValues']
              )).split(splitChar);
            }
            //Split Options by splitchar
            if (
              parameters?.options != null &&
              !Array.isArray(parameters.options)
            ) {
              parameters.options = (<string>parameters['options']).split(
                splitChar
              );
            }
            /*  if (parameters?.order != null) {
              parametersOrdered[parameters?.order] =
                this.objectsUtility.clone(parameters);
            }*/
          });
          if (
            this.arrayUtilityService.isNotNullOrEmptyArray(parametersOrganized)
          ) {
            operation.workOrderChecklistParameters =
              this.arrayUtilityService.clone(parametersOrganized);
          }
        }

        if (
          this.arrayUtilityService.isNotNullOrEmptyArray(
            operation.workOrderChecklistOperations
          )
        ) {
          operation.workOrderChecklistOperations =
            this.splitValuesOptionsIntoArrayAndOrder(
              operation.workOrderChecklistOperations,
              splitChar
            );
        }
      });
    }
    return operations;
  }

  /**
   * Restore parameters options and close values to their original form (string with a separator)
   * @param {WorkOrderChecklistParameter[]} parameters
   * @param {string} separator
   * @returns {WorkOrderChecklistParameter[]}
   */
  public restoreSeparatorIntoValuesOnParameters(
    parameters: WorkOrderChecklistParameter[],
    separator: string
  ) {
    parameters.forEach((parameter) => {
      if (parameter?.closeValues != null) {
        parameter.closeValues = parameter.closeValues.join(separator);
      }

      if (parameter?.options != null) {
        parameter.options = parameter.options.join(separator);
      }
    });
    return parameters;
  }
}
