import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WorkOrderChecklistCardsPage} from "./components/work-order-checklist-cards/work-order-checklist-cards.page";
import {WorkOrderChecklistPage} from './components/work-order-checklist/work-order-checklist.page';

const routes: Routes = [


      {
        path: 'cards',
        component: WorkOrderChecklistCardsPage,
      },
      {
        path: ':checkListId',
        component: WorkOrderChecklistPage,
      },



  /*{
    path: '',
    redirectTo: 'cards',
    pathMatch: 'full',
  },*/

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WorkOrderChecklistRoutingModule {
}
