import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, PrgLayoutsHeaderSidebarComponent } from '@prg/prg-core-lib';

const routes: Routes = [
  {
    component: PrgLayoutsHeaderSidebarComponent,
    path: 'entity-types/ticket/:id',
    loadChildren: () =>
      import(
        './generic-relation-aux-link/generic-relation-aux-link.module'
      ).then((m) => m.GenericRelationAuxLinkModule),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'ticket' }],
  },
  {
    component: PrgLayoutsHeaderSidebarComponent,
    path: 'entity-types/workorder/:id',
    loadChildren: () =>
      import(
        './generic-relation-aux-link/generic-relation-aux-link.module'
      ).then((m) => m.GenericRelationAuxLinkModule),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'workorder' }],
  },
  /*  {
    path: 'tickets-dashboard',
    loadChildren: () =>
      import('./tickets/ticket.module').then((m) => m.TicketModule),
    canActivate: [AuthGuard],
    data: [{ permission: 'dashboard', resource: 'ticket' }],
  },*/
  {
    path: 'indicators',
    loadChildren: () =>
      import('./indicators/indicators.module').then((m) => m.IndicatorsModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'user-availabilities',
    loadChildren: () =>
      import('./user-availabilities/user-availabilities.module').then(
        (m) => m.UserAvailabilitiesModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'list', resource: 'useravailability' }],
  },
  {
    path: 'work-orders',
    loadChildren: () =>
      import('./work-orders/work-orders.module').then(
        (m) => m.WorkOrdersModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'list', resource: 'workorder' }],
  },
  {
    path: 'resources',
    loadChildren: () =>
      import('./resources/resources.module').then((m) => m.ResourcesModule),
    canActivate: [AuthGuard],
    data: [{ permission: 'list', resource: 'resource' }],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.page.module').then((m) => m.UsersPagePageModule),
    canActivate: [AuthGuard],
    data: [{ permission: 'view', resource: 'user' }],
  },

  {
    path: 'checklists',
    loadChildren: () =>
      import('./checklists/checklist.module').then((m) => m.ChecklistModule),
    canActivate: [AuthGuard],
    data: [{ permission: 'list', resource: 'checklist' }],
  },

  {
    path: 'tasks-board',
    loadChildren: () =>
      import('./kanban-board/kanban-board.module').then(
        (m) => m.KanbanBoardModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'kanban', resource: 'ticket' }],
  },

  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications/notifications.module').then(
        (m) => m.NotificationsModule
      ),
    canActivate: [AuthGuard],
    data: [{ permission: 'list', resource: 'notification' }],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
