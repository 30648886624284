import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrgLayoutsHeaderSidebarComponent } from '@prg/prg-core-lib';
import { UserAvailabilitiesCalendarPage } from './components/user-availabilities-calendar/user-availabilities-calendar.page';

const routes: Routes = [
  {
    path: '',
    component: PrgLayoutsHeaderSidebarComponent,
    children: [{ path: '', component: UserAvailabilitiesCalendarPage }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserAvailabilitiesRoutingModule {}
