/**
 * dashboards list
 */
export enum DashboardNames {
  // TicketsManager = 'TechParts-Solar-Tickets',
  TicketsManager = 'TicketsManager',
  // TicketsClient = 'TechParts-Solar-Tickets-Client',
  TicketsClient = 'TicketsClient',
  // HomeManager = 'TechParts-Solar-Home-BO-Alternative',
  HomeManager = 'HomeManager',
  // HomeClient = 'TechParts-Solar-Home-BO',
  HomeClient = 'HomeClient',
  HomeDefault = 'HomeDefault',
  HomeDepartmentManager = 'HomeDepartmentManager',
  IndicatorsManagerMain = 'IndicatorsManagerMain',
  IndicatorsManagerSecondary = 'IndicatorsManagerSecondary',
}

/**
 *application roles list
 */
export enum RoleNames {
  Manager = 'Manager',
  Client = 'Client',
  Technician = 'Technician',
  Admin = 'Admin',
  ApplicationAccess = 'ApplicationAccess',
  DepartmentManager = 'DepartmentManager',
}
