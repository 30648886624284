import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ArrayUtilityService,
  NotificationsService,
  ObjectsUtilityService,
  PageContextService,
  PrgError,
} from '@prg/prg-core-lib';
import { Subscription } from 'rxjs';
import {
  WorkOrder,
  WorkOrderChecklist,
  WorkOrderStates,
} from '../../../../../work-orders/models/work-order-model';

import { WorkOrderEntitiesOperationNames } from '../../../work-order/model/work-order-entities-operation-names-enum';
import { WorkOrderService } from '../../../work-order/services/work-order.service';
import { WorkOrderChecklistEntitiesOperationNames } from '../../model/work-order-checklist-entities-operation-names-enum';
import { WorkOrderChecklistService } from '../../services/work-order-checklist.service';

@Component({
  selector: 'app-checklist-cards',
  templateUrl: './work-order-checklist-cards.page.html',
  styleUrls: ['./work-order-checklist-cards.page.scss'],
})
/**
 * Work Order CheckList Cards Page
 */
export class WorkOrderChecklistCardsPage implements OnInit, OnDestroy {
  /**
   * Worker order id
   * @type {string}
   * @private
   */
  private workOrderId: string;

  /**
   * Current Work order
   * @type {WorkOrder}
   * @private
   */
  private workOrder: WorkOrder;

  /**
   * Work Order Checklists
   * @type {WorkOrderChecklist[]}
   */
  public workOrderCheckLists: WorkOrderChecklist[];

  /**
   * Loading Flag
   * @type {boolean}
   */
  public isLoading = true;

  /**
   * Base translation path for the page in assets
   * @type {string}
   */
  public pageTranslationPath: string =
    'pages-techparts-solar-mobile.work-order-checklist-cards.';

  /**
   * To clean Observables
   * @type {Subscription[]}
   */
  public subscription: Subscription[] = [];

  public workOrderState: string;

  /**
   * Construcotr
   * @param {WorkOrderService} workOrderService
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ActivatedRoute} activatedRoute
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {WorkOrderChecklistService} checklistService
   * @param {NotificationsService} notificationsService
   * @param {Router} router
   * @param translateService
   * @param pageContextService
   */
  constructor(
    private workOrderService: WorkOrderService,
    private objectsUtilityService: ObjectsUtilityService,
    private activatedRoute: ActivatedRoute,
    private arrayUtilityService: ArrayUtilityService,
    private checklistService: WorkOrderChecklistService,
    private notificationsService: NotificationsService,
    private router: Router,
    private translateService: TranslateService,
    private pageContextService: PageContextService
  ) {}

  /**
   * ngOInit
   * @returns {Promise<void>}
   */
  public async ngOnInit(): Promise<void> {
    this.workOrderId = this.activatedRoute.snapshot.paramMap.get('workOrderId');

    if (this.workOrderId) {
      await this.getWorkOrder();
      this.subscription.push(
        this.pageContextService.subscribeVariable(
          'workOrder',
          this.workOrderId,
          this.workOrderUpdate
        )
      );
      this.subscription.push(
        this.pageContextService.subscribeVariable(
          'workOrderChecklistUpdate',
          'workOrderChecklistUpdateTasks',
          this.workOrderChecklistUpdateTasks
        )
      );
    } else {
      /*await this.navigationService.backToPreviousRoute();*/
    }
  }

  public workOrderChecklistUpdateTasks = async (data: WorkOrderChecklist) => {
    if (data != null) {
      const index = this.workOrderCheckLists.findIndex(
        (wc) => wc.id == data.id
      );
      if (index != -1) {
        this.workOrderCheckLists[index] =
          this.objectsUtilityService.clone(data);
      }
    }
  };

  public workOrderUpdate = async (data) => {
    if (data != null) {
      this.workOrder = this.objectsUtilityService.clone(data);

      if (
        this.workOrder.workOrderStateId != this.workOrderState &&
        this.workOrder.workOrderStateId == WorkOrderStates.OnGoing
      ) {
        await this.getCheckLists();
      } else {
        return;
      }
    }
  };

  public workOrderChecklistsData = async (data) => {
    if (data != null) {
      this.workOrderCheckLists = this.arrayUtilityService.clone(data);
      this.isLoading = false;
    }
  };

  /**  //TODO : Check if is necessary getting work order data for this component
   * Method to get Work Order
   * @returns {Promise<void>}
   */
  public async getWorkOrder(): Promise<void> {
    try {
      const responseWorkOrder =
        await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
          'workorder',
          WorkOrderEntitiesOperationNames.ById,
          new WorkOrder({
            id: this.workOrderId,
          })
        );

      if (responseWorkOrder?.entity != null) {
        this.workOrder = this.objectsUtilityService.clone(
          responseWorkOrder?.entity
        );
        this.workOrderState = this.workOrder.workOrderStateId;

        this.subscription.push(
          this.pageContextService.subscribeVariable(
            'workOrderChecklists',
            this.workOrderId,
            this.workOrderChecklistsData
          )
        );

        if (
          this.arrayUtilityService.isNullOrEmptyArray(this.workOrderCheckLists)
        ) {
          await this.getCheckLists();
        }
      }
    } catch (e) {
      /*await this.navigationService.backToPreviousRoute();*/
    }
  }

  /**
   * Method that gets CheckLists for this Work Order
   * @returns {Promise<void>}
   */
  public async getCheckLists(): Promise<void> {
    try {
      const responseChecklist =
        await this.checklistService.manageWorkOrderChecklistDataByEntityServiceAsync(
          'workorderchecklist',
          WorkOrderChecklistEntitiesOperationNames.GetWorkOrderChecklistsByWorkOrderId,
          {
            workOrderId: this.workOrderId,
          }
        );
      if (
        this.arrayUtilityService.isNotNullOrEmptyArray(
          responseChecklist?.entity
        )
      ) {
        this.workOrderCheckLists = this.arrayUtilityService.clone(
          responseChecklist?.entity
        );

        this.workOrderCheckLists.sort((a, b) => (a.order > b.order ? 1 : -1));

        this.pageContextService.setVariableData(
          'workOrderChecklists',
          this.workOrderId,
          this.workOrderCheckLists
        );
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  }

  /**
   * Method that handles navigation for the select checklist
   * @param {string} id
   * @returns {Promise<void>}
   */
  public async navigateTo(id: string): Promise<void> {
    if (this.workOrder.workOrderStateId == WorkOrderStates.Scheduled) {
      this.notificationsService.errorNotification(
        new PrgError({
          titleKey: this.translateService.instant(
            this.pageTranslationPath + 'error-navigation-checklist.title'
          ),
          detailKey: this.translateService.instant(
            this.pageTranslationPath + 'error-navigation-checklist.message'
          ),
        })
      );
    } else {
      const url = this.router.url.split('/cards').shift();

      await this.router.navigate([url, id], {
        skipLocationChange: true,
      });
    }
  }
  ngOnDestroy(): void {
    this.pageContextService.unsubscribe(...this.subscription);
    this.subscription.forEach((subs) => {
      this.pageContextService.unsubscribe(subs);
      subs.unsubscribe();
    });
  }
}
