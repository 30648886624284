export enum WorkOrderChecklistEntitiesOperationNames {
  List = 'list',
  Create = 'create',
  Delete = 'delete',
  Update = 'update',
  UpdateProperty = 'updateproperty',
  Filtered = 'filtered',
  ById = 'byid',
  ByName = 'byname',
  GetWorkOrderChecklistsByWorkOrderId = 'getworkorderchecklistsbyworkorderid',
  GetWorkOrderChecklistWithOperationsAndParametersById = 'getworkorderchecklistwithoperationsandparametersbyid',
}
