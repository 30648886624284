

export enum ResourceEntitiesOperationNames {
  List = 'list',
  Create = 'create',
  Delete = 'delete',
  Update = 'update',
  UpdateProperty = 'updateproperty',
  Filtered = 'filtered',
  ById = 'byid',
  ByName = 'byname',
  ByIdWithChildren = 'byidwithchildren',
  MinimalList = 'minimallist',
  UpdateMultipleQuantities = 'updatemultiplequantities',
  UpdateParkSparesResources = 'updateparksparesresources',
  GeneralAndParkConsumables='generalandparkconsumables'
}


