import { Injectable } from '@angular/core';
import {
  AbstractEntityTypeService,
  ApiGenericService,
  EntityType,
  InterceptorConfig,
  ResultObject,
  User,
} from '@prg/prg-core-lib';
import { UserProfile } from '../../../Core/models/user-profile.model';
import { UserProfileEntitiesOperationNames } from '../../models/user-profile-entities-operation-names-enum';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(
    private entityTypeService: AbstractEntityTypeService,
    private apiGenericService: ApiGenericService
  ) {}

  /**
   * Manage User Profile Data through the entity service
   * @param {string} entityName
   * @param {boolean} onlyEntity
   * @param {UserProfileEntitiesOperationNames} operationName
   * @param entity
   * @returns {Promise<any>}
   */
  public manageUserProfileDataByEntityServiceAsync(
    entityName: string,
    operationName?: UserProfileEntitiesOperationNames,
    entity?: UserProfile,
    onlyEntity: boolean = false
  ): Promise<EntityType | ResultObject> {
    return new Promise<EntityType | ResultObject>((resolve, reject) => {
      this.entityTypeService.getAllEntityTypeDataByName(entityName).then(
        (entityType) => {
          if (entityType != null) {
            if (onlyEntity) {
              resolve(entityType);
              return;
            }
            const operationRequest = entityType.operations.find(
              (operation) => operation.name == operationName
            );

            if (operationRequest != null) {
              this.entityTypeService
                .executeAction(entityName, operationRequest, entity)
                .then(
                  (entityRequest) => {
                    resolve(entityRequest);
                  },
                  (reason) => {
                    reject(reason);
                  }
                );
            }
          }
        },
        (reason) => {
          reject(reason);
        }
      );
    });
  }

  public getUsersByRoleAsync(role: string): Promise<User[]> {
    return new Promise<User[]>((resolve, reject) => {
      this.apiGenericService
        .get(
          `userprofile/ByRole/${role}`,
          null,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
          })
        )
        .then(
          (response) => {
            resolve(response.entity);
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }
}
