import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrgLayoutsHeaderSidebarComponent } from '@prg/prg-core-lib';
import { WorkOrdersCalendarPage } from './components/work-orders-calendar/work-orders-calendar.page';

const routes: Routes = [
  {
    path: '',
    component: PrgLayoutsHeaderSidebarComponent,
    children: [
      {
        path: '',
        component: WorkOrdersCalendarPage,
        children: [
          {
            path: ':workOrderId',
            loadChildren: () =>
              import(
                '../work-order-manager/modules/work-order/work-order.module'
              ).then((m) => m.WorkOrderModule),
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WorkOrdersRoutingModule {}
