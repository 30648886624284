import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@prg/prg-core-lib';
import {
  Months,
  Recurrence,
  RecurringOptions,
  UserAvailability,
  WeekDays,
} from '../../models/user-availability.model';

@Component({
  selector: 'app-user-availability-recurring',
  templateUrl: './user-availability-recurring.component.html',
  styleUrls: ['./user-availability-recurring.component.scss'],
})
export class UserAvailabilityRecurringComponent {
  @Input() availability: UserAvailability;

  private readonly WEEK_DAYS_SEPARATOR = ';';

  public get RecurringOptions() {
    return RecurringOptions;
  }

  public get WeekDays() {
    return WeekDays;
  }

  public recurringOptions = [
    {
      name: this.translateService.instant(
        RecurringOptions.Weekly.toLowerCase() + '.label'
      ),
      value: RecurringOptions.Weekly,
    },
    {
      name: this.translateService.instant(
        RecurringOptions.Monthly.toLowerCase() + '.label'
      ),
      value: RecurringOptions.Monthly,
    },
    {
      name: this.translateService.instant(
        RecurringOptions.Yearly.toLowerCase() + '.label'
      ),
      value: RecurringOptions.Yearly,
    },
  ];

  public weekDayOptions = [
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Sunday + '.label'
      ),
      value: WeekDays.Sunday,
    },
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Monday + '.label'
      ),
      value: WeekDays.Monday,
    },
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Tuesday + '.label'
      ),
      value: WeekDays.Tuesday,
    },
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Wednesday + '.label'
      ),
      value: WeekDays.Wednesday,
    },
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Thursday + '.label'
      ),
      value: WeekDays.Thursday,
    },
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Friday + '.label'
      ),
      value: WeekDays.Friday,
    },
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Saturday + '.label'
      ),
      value: WeekDays.Saturday,
    },
  ];

  public monthOptions = [
    {
      name: this.translateService.instant(
        'months.' + Months.January + '.label'
      ),
      value: Months.January,
    },
    {
      name: this.translateService.instant(
        'months.' + Months.February + '.label'
      ),
      value: Months.February,
    },
    {
      name: this.translateService.instant('months.' + Months.March + '.label'),
      value: Months.March,
    },
    {
      name: this.translateService.instant('months.' + Months.April + '.label'),
      value: Months.April,
    },
    {
      name: this.translateService.instant('months.' + Months.May + '.label'),
      value: Months.May,
    },
    {
      name: this.translateService.instant('months.' + Months.June + '.label'),
      value: Months.June,
    },
    {
      name: this.translateService.instant('months.' + Months.July + '.label'),
      value: Months.July,
    },
    {
      name: this.translateService.instant('months.' + Months.August + '.label'),
      value: Months.August,
    },
    {
      name: this.translateService.instant(
        'months.' + Months.September + '.label'
      ),
      value: Months.September,
    },
    {
      name: this.translateService.instant(
        'months.' + Months.October + '.label'
      ),
      value: Months.October,
    },
    {
      name: this.translateService.instant(
        'months.' + Months.November + '.label'
      ),
      value: Months.November,
    },
    {
      name: this.translateService.instant(
        'months.' + Months.December + '.label'
      ),
      value: Months.December,
    },
  ];

  constructor(
    private translateService: TranslateService,
    private utilityService: UtilityService
  ) {}

  public onIsRecurringChanged(): void {
    if (this.availability.isRecurring) {
      this.availability.recurrence = new Recurrence({
        recurEvery: 1,
        weekDays: WeekDays.Sunday.toString(),
        weekDaysArray: [WeekDays.Sunday],
        recurringOptionId: RecurringOptions.Weekly,
      });
    }
  }

  public onRecurringSelectedChanged(): void {
    const recurStartDate = this.availability.recurrence.startDate;
    const recurEndDate = this.availability.recurrence.endDate;
    switch (this.availability.recurrence.recurringOptionId) {
      case RecurringOptions.Weekly:
        this.availability.recurrence = new Recurrence({
          recurEvery: 1,
          weekDays: WeekDays.Sunday.toString(),
          weekDaysArray: [WeekDays.Sunday],
          recurringOptionId: RecurringOptions.Weekly,
          startDate: recurStartDate,
          endDate: recurEndDate,
        });
        break;
      case RecurringOptions.Monthly:
        this.availability.recurrence = new Recurrence({
          recurEvery: 1,
          monthDay: 1,
          recurringOptionId: RecurringOptions.Monthly,
          startDate: recurStartDate,
          endDate: recurEndDate,
        });
        break;
      case RecurringOptions.Yearly:
        this.availability.recurrence = new Recurrence({
          recurEvery: 1,
          month: 1,
          monthDay: 1,
          recurringOptionId: RecurringOptions.Yearly,
          startDate: recurStartDate,
          endDate: recurEndDate,
        });
        break;
    }
  }

  public onWeekDayOptionsChanged(): void {
    if (
      this.availability.recurrence.weekDaysArray == null ||
      !this.availability.recurrence.weekDaysArray.length
    ) {
      this.availability.recurrence.weekDays = WeekDays.Sunday.toString();
      this.availability.recurrence.weekDaysArray = [WeekDays.Sunday];
    } else {
      this.availability.recurrence.weekDays =
        this.availability.recurrence.weekDaysArray.join(
          this.WEEK_DAYS_SEPARATOR
        );
    }
  }

  onChangeEndDate(event: any): void {
    this.availability.recurrence.endDate =
      this.utilityService.getIsoStringInLocalTime(event);
  }
}
