import { Injectable } from '@angular/core';
import {
  ApiGenericService,
  FilterGroup,
  InterceptorConfig,
  ResultObject,
} from '@prg/prg-core-lib';
import { ParkHierarchyStepper } from '../components/park-hierarchy-stepper/models/park-hierarchy-stepper-model';
import { Resource } from '../Models/resource';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  constructor(private apiGenericService: ApiGenericService) {}

  /**
   *
   * @param resource this function transform a list of serial numbers in a string
   * @returns
   */
  public addResourceWithMultipleSerialNumber(
    resource: any
  ): Promise<Resource[]> {
    const serialNumbersFormatted: string[] = [];
    if (resource.serialNumber != null) {
      (<string[]>resource.serialNumber).forEach((value) => {
        serialNumbersFormatted.push(value.replace(/[\r\n]/gm, ''));
      });
      resource.serialNumber = serialNumbersFormatted.join(';');
    }

    return new Promise<Resource[]>(async (resolve, reject) => {
      const response = await this.apiGenericService.post(
        'resource/addresourcewithmultipleserialnumbers',
        resource,
        new InterceptorConfig({
          apiRequest: true,
          handleLoading: false,
          handleErrors: true,
        })
      );

      resolve(response.entity);
    });
  }

  public getResoucesByClientId(filterGroup: FilterGroup): Promise<Resource[]> {
    return new Promise<Resource[]>(async (resolve, reject) => {
      const response = await this.apiGenericService.post(
        'resource/getresoucesbyclient',
        filterGroup,
        new InterceptorConfig({
          apiRequest: true,
          handleLoading: false,
          handleErrors: true,
        })
      );
      resolve(response.entity);
    });
  }

  public deleteMultipleResources(ids: string[]) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        await this.apiGenericService.put(
          'resource/deletemultipleresources',
          ids,
          null,
          new InterceptorConfig({
            apiRequest: true,
          })
        );
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  public createParkHierarchyByStepper(
    parkHierarchyStepper: ParkHierarchyStepper
  ) {
    return new Promise<ResultObject>(async (resolve, reject) => {
      try {
        let response = await this.apiGenericService.post(
          'resource/createparkhierarchybystepper',
          parkHierarchyStepper,
          new InterceptorConfig({
            apiRequest: true,
          })
        );
        if (response != null) {
          resolve(response);
        } else {
          resolve(null);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  public async createResourcesByExcelFile(
    formData: FormData,
    updateNullValues: boolean
  ) {
    return new Promise<ResultObject>(async (resolve, reject) => {
      try {
        const response = await this.apiGenericService.post(
          `resource/CreateResourcesByExcelFile?updateNullValues=${updateNullValues}`,
          formData,
          new InterceptorConfig({
            apiRequest: true,
          })
        );
        if (response != null) {
          resolve(response);
        } else {
          resolve(null);
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}
