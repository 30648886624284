import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { TranslateService } from '@ngx-translate/core';
import {
  ArrayUtilityService,
  AuthGuard,
  BaseField,
  DynamicDialogService,
  MainLayoutService,
  NavigationService,
  ObjectsUtilityService,
  PageContextService,
  PrgConfigLayouts,
} from '@prg/prg-core-lib';

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { first, Subscription } from 'rxjs';
import {
  OperationValueStates,
  WorkOrder,
  WorkOrderChecklist,
  WorkOrderChecklistOperation,
} from '../../../../../work-orders/models/work-order-model';
import { WorkOrderEntitiesOperationNames } from '../../../work-order/model/work-order-entities-operation-names-enum';
import { WorkOrderService } from '../../../work-order/services/work-order.service';
import { WorkOrderChecklistEntitiesOperationNames } from '../../model/work-order-checklist-entities-operation-names-enum';
import { WorkOrderChecklistService } from '../../services/work-order-checklist.service';
import { WorkOrderChecklistObsPage } from '../work-order-checklist-obs/work-order-checklist-obs.page';
import { WorkOrderChecklistOperationsComponent } from '../work-order-checklist-operations/work-order-checklist-operations.component';
import { WorkOrderOperationHelpTextComponent } from '../work-order-operation-help-text/work-order-operation-help-text.component';

@Component({
  selector: 'app-checklist',
  templateUrl: './work-order-checklist.page.html',
  styleUrls: ['./work-order-checklist.page.scss'],
})
export class WorkOrderChecklistPage implements OnInit, OnDestroy {
  @ViewChild('main', { static: true }) mainContainer: ElementRef;
  /**
   * Current Checklist
   * @type {WorkOrderChecklist[]}
   */
  public workOrderCheckList: WorkOrderChecklist;

  /**
   * Current checklists of work order
   * @type {WorkOrderChecklist[]}
   */
  public workOrderCheckLists: WorkOrderChecklist[] = [];

  /**
   * Items for data view component
   * @type {any[]}
   */
  public items: any[];

  /**
   * sort options for dropdown filter assets
   * @type {any[]}
   */
  public filterOptions: any[] = [];

  /**
   * Sort key for dropdown filter assets
   * @type {any}
   */
  public sortKey: FilterOptionsWorkOrderCheckListOperations;

  /**
   * Loading Flag
   * @type {boolean}
   */
  public isLoading = true;
  /**
   * Form Group of parameters
   * @type {FormGroup}
   */
  public formParameters: FormGroup;

  /**
   * Work order
   * @type {WorkOrder}
   */
  public workOrder: WorkOrder;

  /**
   * Operations of this checklist
   * @type {WorkOrderChecklistOperation[]}
   */
  public checklistOperations: WorkOrderChecklistOperation[] = [];

  /**
   * Children fields parameters for building form on children
   * @type {BaseField[]}
   */
  public fieldsChildrenParameters: BaseField[] = [
    new BaseField({
      key: 'value',
    }),
  ];

  /**
   * checkList order id
   * @type {string}
   * @private
   */
  private workOrderChecklistId: string;
  /**
   * work order id
   * @type {string}
   * @private
   */
  private workOrderId: string;
  /**
   * Variable to unsubscribe observables
   * @type {Subscription[]}
   * @private
   */
  private subscription: Subscription[] = [];

  /**
   * Variable to unsubscribe observables from page context
   * @type {Subscription[]}
   * @private
   */
  private subscriptionPageContext: Subscription[] = [];

  /**
   * Property of type DynamicDialogRef to control the dialog of checklist-obs
   */
  private dynamicDialogRef!: DynamicDialogRef;

  /**
   * Property of type DynamicDialogRef to control the dialog of help text
   */
  private dynamicDialogRefHelpText!: DynamicDialogRef;

  /**
   * Property of type DynamicDialogRef to control the dialog of operations
   */
  private dynamicDialogRefOperations!: DynamicDialogRef;

  /**
   * Base translation path for the page in assets
   * @type {string}
   */
  public pageTranslationPath: string =
    'pages-techparts-solar-mobile.work-order-checklist.';

  /**
   * An object that store operations child ids and his view mode
   * @type {any}
   */
  public showOperations: any = {};

  public mainScrollObject: any;

  public hasGroupedOperations: boolean = false;

  public operationsGrouped: any[] = [];

  public isGrantedToEnableFormOnComplete: boolean = false;

  /**
   * Constructor
   * @param {DialogService} dialogService
   * @param {NavigationService} navigationService
   * @param {WorkOrderService} workOrderService
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ActivatedRoute} activatedRoute
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {WorkOrderChecklistService} checklistService
   * @param {FormBuilder} formBuilder
   * @param translateService
   * @param pageContextService
   * @param location
   * @param cd
   * @param mainLayoutService
   * @param authGuard
   */
  constructor(
    public dialogService: DynamicDialogService,
    public navigationService: NavigationService,
    private workOrderService: WorkOrderService,
    private objectsUtilityService: ObjectsUtilityService,
    private activatedRoute: ActivatedRoute,
    private arrayUtilityService: ArrayUtilityService,
    private checklistService: WorkOrderChecklistService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private pageContextService: PageContextService,
    public location: Location,
    private cd: ChangeDetectorRef,
    private mainLayoutService: MainLayoutService,
    private authGuard: AuthGuard
  ) {}

  /**
   * ngOnInit
   * @returns {Promise<void>}
   */
  public async ngOnInit(): Promise<void> {
    if (this.authGuard.isGranted('workorder:change-on-complete', null, null)) {
      this.isGrantedToEnableFormOnComplete = true;
    }
    this.formParameters = this.formBuilder.group({});
    this.workOrderChecklistId =
      this.activatedRoute.snapshot.paramMap.get('checkListId');
    this.workOrderId = this.activatedRoute.snapshot.paramMap.get('workOrderId');

    if (this.workOrderChecklistId) {
      this.subscriptionPageContext.push(
        this.pageContextService.subscribeVariable(
          'workOrderChecklist',
          this.workOrderChecklistId,
          this.workOrderChecklistDataCache
        )
      );
      if (this.workOrderCheckList == null) {
        await this.getChecklist();
      } else {
        this.checkIfOperationsAreGrouped();
        this.updateView(this.workOrderCheckList.workOrderChecklistOperations);

        this.getFilterOptions(
          this.workOrderCheckList.workOrderChecklistOperations
        );
        this.removeDuplicatesFromFilterOptions();
      }
      this.subscription.push(
        this.pageContextService.subscribeVariable(
          'workOrderChecklists',
          this.workOrderCheckList.workOrderId,
          this.workOrderChecklistsData
        )
      );
      this.subscriptionPageContext.push(
        this.pageContextService.subscribeVariable(
          'workOrder',
          this.workOrderCheckList.workOrderId,
          this.workOrderDataCache
        )
      );

      this.mainLayoutService.setLayoutsConfig(
        new PrgConfigLayouts({
          headerSidebarSpinner: false,
          headerSidebarOverlay: true,
        })
      );

      if (this.workOrder == null) {
        await this.getWorkOrderInfo();
      }
    } else {
      await this.navigationService.backToPreviousRoute();
    }
  }

  public checkIfOperationsAreGrouped() {
    this.hasGroupedOperations =
      this.workOrderCheckList.workOrderChecklistOperations.some(
        (op) => op.operationGroupId != null
      );
  }

  /**
   * Get work order data stored in cache
   * @param data
   */
  public workOrderDataCache = (data) => {
    if (this.workOrderCheckList != null && data != null) {
      this.workOrder = this.objectsUtilityService.clone(data);
      this.isLoading = false;
    }
  };
  /**
   * Get work order checklists data stored in cache
   * @param data
   * @returns {Promise<void>}
   */
  public workOrderChecklistsData = async (data) => {
    if (data != null) {
      this.workOrderCheckLists = this.arrayUtilityService.clone(data);
    }
  };
  /**
   * Get work order checklist data stored in cache
   * @param data
   */
  public workOrderChecklistDataCache = (data) => {
    if (data != null) {
      this.workOrderCheckList = this.objectsUtilityService.clone(data);
    }
  };

  /** TODO:Check if is necessary in this page work order data
   * Method to get work Order Info
   * @returns {Promise<void>}
   */
  private async getWorkOrderInfo(): Promise<void> {
    if (this.workOrderCheckList != null) {
      try {
        const responseWorkOrder =
          await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
            'workorder',
            WorkOrderEntitiesOperationNames.ById,
            new WorkOrder({
              id: this.workOrderCheckList.workOrderId,
            })
          );

        if (responseWorkOrder?.entity != null) {
          this.workOrder = this.objectsUtilityService.clone(
            responseWorkOrder?.entity
          );
          this.isLoading = false;
        }
      } catch (e) {
        /*await this.navigationService.backToPreviousRoute();*/
        this.isLoading = false;
      }
    }
  }

  /**
   * Method to get CheckList
   * @returns {Promise<void>}
   */
  private async getChecklist(): Promise<void> {
    try {
      const responseWorkOrderChecklist =
        await this.checklistService.manageWorkOrderChecklistDataByEntityServiceAsync(
          'workorderchecklist',
          WorkOrderChecklistEntitiesOperationNames.GetWorkOrderChecklistWithOperationsAndParametersById,
          new WorkOrderChecklist({
            id: this.workOrderChecklistId,
          })
        );

      if (responseWorkOrderChecklist?.entity != null) {
        this.workOrderCheckList = this.objectsUtilityService.clone(
          responseWorkOrderChecklist?.entity
        );
        this.organizeWorkOrderCheckList();
      }
    } catch (e) {
      this.isLoading = false;
    }
  }

  /**
   * Method to organize WO checklist
   */
  private organizeWorkOrderCheckList() {
    this.workOrderCheckList.workOrderChecklistOperations =
      this.arrayUtilityService.clone(
        this.checklistService.splitValuesOptionsIntoArrayAndOrder(
          this.workOrderCheckList.workOrderChecklistOperations,
          ';'
        )
      );
    this.checkIfOperationsAreGrouped();
    this.updateView(this.workOrderCheckList.workOrderChecklistOperations);
    this.getFilterOptions(this.workOrderCheckList.workOrderChecklistOperations);
    this.removeDuplicatesFromFilterOptions();
  }

  /**
   * Method to get filter options for dropdown
   * @param {WorkOrderChecklistOperation[]} operations
   */
  private getFilterOptions(operations: WorkOrderChecklistOperation[]) {
    operations.map((op) => {
      if (op.operationGroupId != null && op.operationGroup != null) {
        const index = this.filterOptions.findIndex(
          (x) => x.name == 'operationgroup'
        );
        if (index != -1) {
          this.filterOptions[index]['items'].push({
            id: op.operationGroup.id,
            name: op.operationGroup.name,
            type: 'operationgroup',
          });
        } else {
          this.filterOptions.push({
            id: 'operationgroup',
            name: 'operationgroup',
            items: [
              {
                id: op.operationGroup.id,
                name: op.operationGroup.name,
                type: 'operationgroup',
              },
            ],
          });
        }
      }
      if (op?.resourceId != null) {
        const index = this.filterOptions.findIndex((x) => x.name == 'resource');
        if (index != -1) {
          this.filterOptions[index]['items'].push({
            id: op.id,
            name: op.name,
            type: 'resource',
          });
        } else {
          this.filterOptions.push({
            id: 'resource',
            name: 'resource',
            items: [{ id: op.name, name: op.name, type: 'resource' }],
          });
        }
      }

      if (
        this.arrayUtilityService.isNotNullOrEmptyArray(
          op.workOrderChecklistOperations
        )
      ) {
        this.getFilterOptions(op.workOrderChecklistOperations);
      }
    });
  }
  public groupOperations(
    workOrderChecklistOperations: WorkOrderChecklistOperation[]
  ) {
    this.operationsGrouped = [];
    workOrderChecklistOperations.forEach((op) => {
      if (op.operationGroupId != null) {
        const indexOpGrouped = this.operationsGrouped.findIndex(
          (x) => x.id == op.operationGroupId
        );
        if (indexOpGrouped != -1) {
          this.operationsGrouped[indexOpGrouped]['checklistOperations'].push(
            op
          );
        } else {
          this.operationsGrouped.push({
            id: op.operationGroup.id,
            name: op.operationGroup.name,
            order: op.operationGroup.order,
            checklistOperations: [op],
          });
        }
      } else {
        const indexOpNotGrouped = this.operationsGrouped.findIndex(
          (x) => x.id == 'ungrouped'
        );
        if (indexOpNotGrouped != -1) {
          this.operationsGrouped[indexOpNotGrouped]['checklistOperations'].push(
            op
          );
        } else {
          this.operationsGrouped.push({
            id: 'ungrouped',
            checklistOperations: [op],
          });
        }
      }
    });

    if (
      this.arrayUtilityService.isNotNullOrEmptyArray(this.operationsGrouped)
    ) {
      this.operationsGrouped = this.operationsGrouped.sort((a, b) => {
        if (a?.order == null) {
          return 1;
        }
        if (b?.order == null) {
          return -1;
        }
        if (a?.order === b?.order) {
          return 0;
        }

        return a?.order < b?.order ? -1 : 1;
      });
    }
  }

  public removeDuplicatesFromFilterOptions() {
    if (this.arrayUtilityService.isNotNullOrEmptyArray(this.filterOptions)) {
      const indexGroup = this.filterOptions.findIndex(
        (f) => f?.name == 'operationgroup'
      );
      if (indexGroup != -1 && indexGroup == 1) {
        const filterResources = this.objectsUtilityService.clone(
          this.filterOptions[0]
        );
        this.filterOptions[0] = this.objectsUtilityService.clone(
          this.filterOptions[1]
        );
        this.filterOptions[1] = filterResources;
      }
      this.filterOptions.forEach((x) => {
        x.items = this.arrayUtilityService.clone(
          this.arrayUtilityService.removeDuplicatesByPropertyValue(
            x.items,
            'name'
          )
        );
      });
    }
  }

  /**
   * Method for updating view after changes
   * @param items
   * @private
   */
  private updateView(items) {
    this.checklistOperations = this.arrayUtilityService.clone(items);
    this.groupOperations(this.checklistOperations);
    this.pageContextService.setVariableData(
      'workOrderChecklist',
      this.workOrderCheckList.id,
      this.workOrderCheckList
    );
  }

  /**
   * ngOnDestroy
   */
  public ngOnDestroy(): void {
    this.mainLayoutService.setLayoutsConfig(new PrgConfigLayouts({}));
    this.pageContextService.unsubscribe(...this.subscriptionPageContext);
    this.subscription.forEach((subs) => {
      subs.unsubscribe();
    });
  }

  /**
   * Method that handles actions in result of changes on parameters children components
   * @param {WorkOrderChecklistOperation} event
   * @param scroll
   * @param i
   * @param subIndex
   * @returns {Promise<void>}
   */
  public async onHandleResultParameters(
    event: WorkOrderChecklistOperation,
    i: number,
    subIndex: number = null
  ): Promise<void> {
    const operationToUpdate = this.objectsUtilityService.clone(event);
    /*this.findOperationByIdToUpdate(operationToUpdate, i, subIndex);*/
    operationToUpdate.workOrderChecklistParameters =
      this.arrayUtilityService.clone(
        this.checklistService.restoreSeparatorIntoValuesOnParameters(
          operationToUpdate.workOrderChecklistParameters,
          ';'
        )
      );
    this.checklistService
      .manageWorkOrderChecklistDataByEntityServiceAsync(
        'workorderchecklistoperation',
        WorkOrderChecklistEntitiesOperationNames.Update,
        operationToUpdate
      )
      .then((responseOperationUpdate) => {
        if (responseOperationUpdate?.entity != null) {
          if (
            !this.compareOperations(
              operationToUpdate,
              responseOperationUpdate?.entity
            )
          ) {
            const aux = this.arrayUtilityService.clone(
              this.checklistService.splitValuesOptionsIntoArrayAndOrder(
                [responseOperationUpdate?.entity],
                ';'
              )
            );
            this.findOperationByIdToUpdate(aux[0], i, subIndex);
          } else {
            this.findOperationByIdToUpdate(event, i, subIndex);
          }
        } else {
          this.updateView(this.workOrderCheckList.workOrderChecklistOperations);
        }
      })
      .catch(() => {
        this.updateView(this.workOrderCheckList.workOrderChecklistOperations);
      });
  }
  /**
   * This function compare data from operation that was changed in view and the one that comes from backend
   * @param {WorkOrderChecklistOperation} frontOperation
   * @param {WorkOrderChecklistOperation} backOperation
   * @returns {boolean}
   */
  public compareOperations(
    frontOperation: WorkOrderChecklistOperation,
    backOperation: WorkOrderChecklistOperation
  ) {
    let check = true;
    if (
      frontOperation.operationValueStateId !=
      backOperation.operationValueStateId
    ) {
      check = false;
    }
    frontOperation.workOrderChecklistParameters.forEach((param) => {
      const auxParam = backOperation.workOrderChecklistParameters.find(
        (p) => p.id == param.id
      );
      if (auxParam != null) {
        if (auxParam.value != param.value) {
          check = false;
        }
      }
    });

    return check;
  }

  /**
   * Method that handles actions after filter was selected on dropdown
   * @param event
   * @param selectedOption
   */
  public onFilterChange(
    event: any,
    selectedOption: FilterOptionsWorkOrderCheckListOperations
  ): void {
    if (event.value != null && selectedOption.type == 'resource') {
      const operationCloned: WorkOrderChecklistOperation[] =
        this.arrayUtilityService.clone(
          this.workOrderCheckList.workOrderChecklistOperations
        );
      const operationChildren: WorkOrderChecklistOperation[] = [];
      const operationParent: WorkOrderChecklistOperation[] = [];
      operationCloned
        .filter((operation) =>
          this.arrayUtilityService.isNotNullOrEmptyArray(
            operation.workOrderChecklistOperations
          )
        )
        .map((item) => {
          item.workOrderChecklistOperations.map((itemChildren) => {
            if (itemChildren.name == selectedOption.name) {
              operationParent.push(item);
              operationChildren.push(itemChildren);
            }
          });
        });
      if (
        this.arrayUtilityService.isNotNullOrEmptyArray(operationParent) &&
        this.arrayUtilityService.isNotNullOrEmptyArray(operationChildren)
      ) {
        operationParent.forEach((op, index) => {
          op.workOrderChecklistOperations = [];
          op.workOrderChecklistOperations.push(operationChildren[index]);
        });
        this.showOperations = {};
        this.mainContainer.nativeElement.scrollTop = 0;
        this.updateView(operationParent);
      }
    } else if (event.value != null && selectedOption.type == 'operationgroup') {
      const operationsGroupedFilter = this.arrayUtilityService.clone(
        this.workOrderCheckList.workOrderChecklistOperations.filter(
          (op) => op.operationGroupId == event.value
        )
      );
      this.showOperations = {};
      this.mainContainer.nativeElement.scrollTop = 0;
      this.updateView(operationsGroupedFilter);
    } else {
      this.showOperations = {};
      this.mainContainer.nativeElement.scrollTop = 0;
      this.updateView(this.workOrderCheckList.workOrderChecklistOperations);
    }
  }

  /**
   * Method that find operation by id to perform an update on view
   * @param {WorkOrderChecklistOperation} operationToUpdate
   * @param index
   * @param subIndex
   */
  private findOperationByIdToUpdate(
    operationToUpdate: WorkOrderChecklistOperation,
    index: number,
    subIndex: number
  ) {
    const aux = this.workOrderCheckList.workOrderChecklistOperations.find(
      (op) => op.id == operationToUpdate.id
    );

    if (aux) {
      this.updateOperationsCompleted(
        aux.operationValueStateId,
        operationToUpdate.operationValueStateId
      );
      aux.workOrderChecklistParameters = this.arrayUtilityService.clone(
        operationToUpdate.workOrderChecklistParameters
      );
      this.checklistOperations[index].workOrderChecklistParameters =
        this.arrayUtilityService.clone(
          operationToUpdate.workOrderChecklistParameters
        );
      aux.operationValueStateId = operationToUpdate.operationValueStateId;
    } else {
      this.workOrderCheckList.workOrderChecklistOperations.forEach(
        (operation) => {
          if (
            this.arrayUtilityService.isNotNullOrEmptyArray(
              operation.workOrderChecklistOperations
            )
          ) {
            const aux = operation.workOrderChecklistOperations.find(
              (op) => op.id == operationToUpdate.id
            );
            if (aux != null) {
              this.updateOperationsCompleted(
                aux.operationValueStateId,
                operationToUpdate.operationValueStateId
              );
              aux.workOrderChecklistParameters = this.arrayUtilityService.clone(
                operationToUpdate.workOrderChecklistParameters
              );
              aux.operationValueStateId =
                operationToUpdate.operationValueStateId;

              this.checklistOperations[index].workOrderChecklistOperations[
                subIndex
              ].workOrderChecklistParameters = this.arrayUtilityService.clone(
                operationToUpdate.workOrderChecklistParameters
              );
              this.checklistOperations[index].workOrderChecklistOperations[
                subIndex
              ].operationValueStateId = operationToUpdate.operationValueStateId;
              return;
            }
          }
        }
      );
    }

    this.pageContextService.setVariableData(
      'workOrderChecklist',
      this.workOrderCheckList.id,
      this.workOrderCheckList
    );
  }

  /**
   * Method that updates the completed operations on view
   * @param {OperationValueStates | string} oldValue
   * @param {OperationValueStates | string} newValue
   * @private
   */
  private updateOperationsCompleted(
    oldValue: OperationValueStates | string,
    newValue: OperationValueStates | string
  ) {
    if (oldValue != newValue) {
      if (newValue === OperationValueStates.Closed) {
        this.workOrderCheckList.completedTasks++;
      } else if (
        this.workOrderCheckList.completedTasks > 0 &&
        newValue == OperationValueStates.Open
      ) {
        this.workOrderCheckList.completedTasks--;
      }
      if (
        this.arrayUtilityService.isNotNullOrEmptyArray(this.workOrderCheckLists)
      ) {
        const workOrderChecklistToUpdate = this.workOrderCheckLists.find(
          (w) => w.id == this.workOrderCheckList.id
        );

        if (workOrderChecklistToUpdate != null) {
          workOrderChecklistToUpdate.completedTasks =
            this.workOrderCheckList.completedTasks;
          this.pageContextService.setVariableData(
            'workOrderChecklists',
            this.workOrderCheckList.workOrderId,
            this.workOrderCheckLists
          );
        }
      }
    }
  }

  /**
   * Method that open a dialog to show notes of the selected operation
   * @param {WorkOrderChecklistOperation} item
   * @param {number} indexData
   * @param {number} indexOperation
   * @param {number} indexChildrenOperation
   */
  public onOpenObs(
    item: WorkOrderChecklistOperation,
    indexData: number,
    indexOperation: number,
    indexChildrenOperation: number = null
  ): void {
    this.dynamicDialogRef = this.dialogService.openDialog(
      WorkOrderChecklistObsPage,
      {
        height: '90vh',
        header:
          this.translateService.instant('components.notes-chip.dialog-title') +
          item.name,
        width: '100%',
        styleClass: 'dialog-obs',
        style: { 'max-width': '550px' },
        autoZIndex: false,
        modal: true,
        draggable: false,
        data: {
          checklistOperation: item,
        },
      }
    );

    this.dynamicDialogRef.onClose.pipe(first()).subscribe((item) => {
      if (item != null) {
        if (indexChildrenOperation == null) {
          this.checklistOperations[
            indexOperation
          ].workOrderChecklistOperationNotes =
            this.arrayUtilityService.clone(item);
        } else {
          this.checklistOperations[indexOperation].workOrderChecklistOperations[
            indexChildrenOperation
          ].workOrderChecklistOperationNotes =
            this.arrayUtilityService.clone(item);
        }
        this.pageContextService.setVariableData(
          'workOrderChecklist',
          this.workOrderCheckList.id,
          this.workOrderCheckList
        );
      }
    });
  }

  /**
   * Method that open a dialog to show help text of the selected operation
   * @param {WorkOrderChecklistOperation} item
   */
  public onOpenHelpTextDialog(item: WorkOrderChecklistOperation): void {
    this.dynamicDialogRefHelpText = this.dialogService.openDialog(
      WorkOrderOperationHelpTextComponent,
      {
        height: '90vh',
        header:
          this.translateService.instant(
            'pages-techparts-solar-mobile.work-order-operation-help-text.title'
          ) + item.name,
        width: '100%',
        styleClass: 'dialog-help-text',
        style: { 'max-width': '550px' },
        autoZIndex: false,
        modal: true,
        draggable: false,
        data: {
          helpText: item.helpText,
        },
      }
    );
  }

  /**
   * Method that open a dialog to add a new operation
   */
  public onOpenOperationsDialog(): void {
    this.dynamicDialogRefOperations = this.dialogService.openDialog(
      WorkOrderChecklistOperationsComponent,
      {
        height: '95vh',
        header: this.translateService.instant(
          'pages-techparts-solar-mobile.work-order-checklist-operations.title'
        ),
        width: '100%',
        styleClass: 'dialog-wo-operations',
        style: { 'max-width': '550px' },
        autoZIndex: false,
        modal: true,
        draggable: false,
        data: {
          workOrderChecklist: this.workOrderCheckList,
          workOrder: this.workOrder,
        },
      }
    );

    this.dynamicDialogRefOperations.onClose
      .pipe(first())
      .subscribe(async (item) => {
        if (item != null) {
          this.workOrderCheckList.workOrderChecklistOperations.push(
            item?.workOrderChecklistOperation
          );
          this.workOrderCheckList.numberOfTasks = item?.numberOfTasks;
          this.organizeWorkOrderCheckList();

          if (
            this.arrayUtilityService.isNotNullOrEmptyArray(
              this.workOrderCheckLists
            )
          ) {
            const workOrderChecklistToUpdate = this.workOrderCheckLists.find(
              (w) => w.id == this.workOrderCheckList.id
            );

            if (workOrderChecklistToUpdate != null) {
              workOrderChecklistToUpdate.numberOfTasks =
                this.workOrderCheckList.numberOfTasks;
              this.pageContextService.setVariableData(
                'workOrderChecklists',
                this.workOrderCheckList.workOrderId,
                this.workOrderCheckLists
              );
            }
          }
        }
      });
  }
  /**
   * Show hide operations
   * @param {string} id
   */
  toggleOperations(id: string, $event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    /*Object.keys(this.showOperations).forEach((key) => {
      if (key != id) {
        this.showOperations[key] = false;
      }
      this.cd.detectChanges();
    });*/

    if (id in this.showOperations) {
      this.showOperations[id] = !this.showOperations[id];
    } else {
      this.showOperations[id] = true;
    }
  }

  onUpdateScroll(
    scroll: VirtualScrollerComponent,
    main: HTMLElement,
    parentScroll: HTMLDivElement
  ): void {
    setTimeout(() => {
      if (
        main?.clientHeight +
          main?.scrollTop -
          parentScroll?.offsetTop -
          20 -
          (this.workOrderCheckList.allowAdditionalOperations == true ? 50 : 0) >
        300
      ) {
        scroll['contentElementRef'].nativeElement.parentElement.style.height =
          main?.clientHeight +
          main?.scrollTop -
          parentScroll?.offsetTop -
          20 -
          (this.workOrderCheckList.allowAdditionalOperations == true ? 50 : 0) +
          'px';
      }
    }, 150);
  }
  onMainScroll($event: any): void {
    this.mainScrollObject = {
      clientHeight: $event.target.clientHeight,
      scrollTop: $event.target.scrollTop,
    };
  }
}

export interface FilterOptionsWorkOrderCheckListOperations {
  id: string;
  name: string;
  type: string;
}
