import { BaseEntity } from '@prg/prg-core-lib';

export enum Months {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export enum WeekDays {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum RecurringOptions {
  Weekly = 'recurringoptions.weekly',
  Monthly = 'recurringoptions.monthly',
  Yearly = 'recurringoptions.yearly',
}

export class Recurrence extends BaseEntity {
  public startDate: Date | string;
  public endDate: Date | string;
  public recurringOptionId: RecurringOptions;
  public recurEvery: number;
  public weekDays: string;
  public weekDaysArray: WeekDays[];
  public monthDay: number;
  public month: number;

  constructor(init?: Partial<Recurrence>) {
    super();
    Object.assign(this, init);
  }
}

export class DateRange {
  public startDate: Date;
  public endDate: Date;
  constructor(init?: Partial<DateRange>) {
    Object.assign(this, init);
  }
}

export class UserAvailability extends BaseEntity {
  public startDate: Date | string;
  public endDate: Date | string;
  public isOnCall: boolean;
  public isRecurring: boolean;
  public recurrence: Recurrence;
  public recurrenceId: string;
  public userId: string | string[];
}

export class AddUserAvailabilitiesRequest {
  public userIds: string[];
  public userAvailability: UserAvailability;

  constructor(init?: Partial<AddUserAvailabilitiesRequest>) {
    Object.assign(this, init);
  }
}

export class AddUserAvailabilitiesResponse {
  public addedAvailabilities: UserAvailability[];
  public removedAvailabilities: UserAvailability[];

  constructor(
    addedAvailabilities: UserAvailability[],
    removedAvailabilities: UserAvailability[]
  ) {
    this.addedAvailabilities = addedAvailabilities;
    this.removedAvailabilities = removedAvailabilities;
  }
}

export class UpdatedUserAvailabilitiesResponse {
  public addedAvailabilities?: UserAvailability[];
  public removedAvailabilities?: string[];

  constructor(
    addedAvailabilities: UserAvailability[],
    removedAvailabilities: string[]
  ) {
    this.addedAvailabilities = addedAvailabilities;
    this.removedAvailabilities = removedAvailabilities;
  }
}

export class UpdateUserAvailabilityResponse {
  public updatedAvailability: UserAvailability;
  public removedAvailabilities: UserAvailability[];

  constructor(
    updatedAvailability: UserAvailability,
    removedAvailabilities: UserAvailability[]
  ) {
    this.updatedAvailability = updatedAvailability;
    this.removedAvailabilities = removedAvailabilities;
  }
}

export class CalendarLegend {
  public label: string;
  public color: string;
  public backgroundColor: string;
  public icon: string;
  public count: number;
  public data?: any;

  constructor(init?: Partial<CalendarLegend>) {
    Object.assign(this, init);
  }
}
