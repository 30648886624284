import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  PageContextService,
  PrgCoreDynamicFormModule,
  PrgCoreModule,
  PrgEntityTypesModule,
  PrgSharedComponentsModule,
  SecurePipe,
} from '@prg/prg-core-lib';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { OrderListModule } from 'primeng/orderlist';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { ListDragDropComponent } from './components/list-drag-drop/list-drag-drop.component';
import { UserAvailabilitiesCalendarPage } from './components/user-availabilities-calendar/user-availabilities-calendar.page';
import { UserAvailabilityRecurringComponent } from './components/user-availability-recurring/user-availability-recurring.component';
import { UserAvailabilitiesRoutingModule } from './user-availabilities-routing.module';

@NgModule({
  declarations: [
    UserAvailabilitiesCalendarPage,
    UserAvailabilityRecurringComponent,
    ListDragDropComponent,
  ],
  imports: [
    BadgeModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    FullCalendarModule,
    OrderListModule,
    DialogModule,
    ButtonModule,
    AvatarModule,
    ChipModule,
    BadgeModule,
    TooltipModule,
    ToolbarModule,
    DragDropModule,
    CheckboxModule,
    SelectButtonModule,
    InputNumberModule,
    DropdownModule,
    CalendarModule,
    PrgCoreModule,
    PrgEntityTypesModule,
    UserAvailabilitiesRoutingModule,
    PrgCoreDynamicFormModule,
    PrgSharedComponentsModule,
    SecurePipe,
    ToggleButtonModule,
  ],
  providers: [PageContextService],
  exports: [ListDragDropComponent],
})
export class UserAvailabilitiesModule {}
