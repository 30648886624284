export enum WorkOrderEntitiesOperationNames {
  List = 'list',
  Create = 'create',
  Delete = 'delete',
  Update = 'update',
  UpdateProperty = 'updateproperty',
  Filtered = 'filtered',
  ById = 'byid',
  ByName = 'byname',
  FilteredWithTimers = 'filteredwithtimers',
  ByIdWithResource = 'byidwithresource',
  ByIdWithTimers = 'byidwithtimers',
  FinishWorkOrder = 'finishworkorder',
  ReopenWorkOrder = 'reopenworkorder',
  CloseWorkOrder = 'closeworkorder',
  CreateSignatureWorkOrder='createsignatureworkorder',
UpdateWorkOrderTimers='updateworkordertimers'

}
